import './StokBahan.scss';
import React, { useEffect, useState } from 'react';
import * as stokBahanApi from '../../stores/business/StokBahanApi';
import { 
  Space, 
  Modal,
  notification,
  Divider,
} from 'antd';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

const Context = React.createContext({
  name: 'Default',
});

const StokBahanMobile = () => {
  const { stokId } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (message, description, type) => {
    if (type === "error") {
      api.error({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    } else if (type === "success") {
      api.success({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    }
  };

  const fetchStokData = async () => {
    const response = await dispatch(stokBahanApi.getStokById(stokId));
    if (response.status === 200) {
      setData(response.data);
      console.log(response.data);
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
  };

  const setRedirectTo = () => {
    window.localStorage.setItem('reason-season-redirect-to', "/stok/bahan/mobile/"+stokId);
  }
  
  useEffect(() => {
    setRedirectTo();
    if (!isMobile) {
      setIsOpenModal(true);
    }
    fetchStokData();
  }, []);

  return (
    <Context.Provider value={true}>
      {contextHolder}

      <div className='stok-bahan-mobile' style={{padding: '20px 24px'}}>
        <label className='title'>Jenis Bahan</label>
        <h1>{data?.jenisBahan}</h1>

        <Divider />

        <Space>
          <p className='detail-item-key'>Ketebalan Bahan</p>
          <p className='detail-item-value'>{data?.ketebalanBahan}s</p>
        </Space>

        <Divider />
        
        <Space>
          <p className='detail-item-key'>Gramasi</p>
          <p className='detail-item-value'>{data?.gramasi}</p>
        </Space>

        <Divider />
        
        <Space>
          <p className='detail-item-key'>Warna Bahan</p>
          <p className='detail-item-value'>{data?.warnaBahan}</p>
        </Space>

        <Divider />
        
        <Space>
          <p className='detail-item-key'>Setting Bahan</p>
          <p className='detail-item-value'>{data?.settingBahan} {data?.jenisSettingBahan}</p>
        </Space>

        <Divider />
        
        <Space>
          <p className='detail-item-key'>Jumlah Berat</p>
          <p className='detail-item-value'>{data?.totalBeratRoll} Kg</p>
        </Space>

        <Divider />
      </div>

      <Modal
        title=""
        centered
        open={isOpenModal}
        onCancel={() => setIsOpenModal(false)}
        footer={[]}
      >
        <p style={{textAlign:'center', fontSize: '16px'}}>Please open in your mobile phone for better experience</p>
      </Modal>
    </Context.Provider>
  );
}

export default StokBahanMobile;