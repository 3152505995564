import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { thunk } from 'redux-thunk';
import logger from 'redux-logger';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import AppSlice from './reducers/AppSlice'

export const store = configureStore({
  reducer: {
    appStore: AppSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk, logger, reduxImmutableStateInvariant())
})

export default store;
