import { CallRestApiSecure } from '../../libs/Api';

export const createUser = (payload) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('post', BASE_URL, 'v1/master-user/create', payload, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const editUser = (payload, userId) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('post', BASE_URL, 'v1/master-user/update/' + userId, payload, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const getUserPage = (payload) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('post', BASE_URL, 'v1/master-user/page', payload, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const deleteUser = (payload) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('delete', BASE_URL, 'v1/master-user/delete/' + payload, null, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const getUserHasNoRole = (roleCode, payload) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('post', BASE_URL, 'v1/master-user/list/no-role/' + roleCode, payload, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const getUserHasRole = (roleCode, payload) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('post', BASE_URL, 'v1/master-user/list/role/' + roleCode, payload, {}, {}, 'application/json');
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};