import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Flex, Button, Checkbox, Form, Input, notification } from 'antd';
import { SaveToken } from '../../libs/Auth';
import * as auth from '../../stores/business/AuthApi';
import bg1 from '../../assets/bg-1.jpeg';
import bg2 from '../../assets/bg-2.jpeg';
import bg3 from '../../assets/bg-3.jpeg';
import './Login.scss';
import Logo from '../../components/Logo';

const Context = React.createContext({
  name: 'Default',
});

const Login = () => {
  const [api, contextHolder] = notification.useNotification();
  const [background, setBackground] = useState(bg1);
  const dispatch = useDispatch();
  const nav = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      if (background === bg1) {
        setBackground(bg2);
      } else if (background === bg2) {
        setBackground(bg3);
      } else {
        setBackground(bg1);
      }
    }, 5000);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (background === bg1) {
        setBackground(bg2);
      } else if (background === bg2) {
        setBackground(bg3);
      } else {
        setBackground(bg1);
      }
    }, 5000);
  }, [background]);

  const openNotification = (message, description) => {
    api.error({
      duration: 3,
      message: message,
      description: description,
      placement: 'top',
    });
  };

  const onFinish = async (values) => {
    let redirectTo = window.localStorage.getItem('reason-season-redirect-to');
    const response = await dispatch(auth.login({
      username: values.username,
      password: values.password,
      isRememberMe: values.remember,
    }));
    if (response.status === 200) {
      SaveToken(response.data);

      if (redirectTo !== null && redirectTo !== "") {
        nav(redirectTo);
      } else {
        nav('/');
      }
    } else {
      openNotification('Error Login', response.errors[0].message);
    }
  };

  return (
    <Context.Provider value={true}>
      {contextHolder}
      <Flex gap="large" vertical={false} className="login-container">
        <div className="left-column">
          <div className="greetings">
            <Logo className="logo-login"></Logo>
            {/* <h1>Welcome!</h1>
            <label style={{display:"block"}}>Please input your details</label> */}
          </div>
          <Form
            name="login-form"
            className="login-form"
            layout="vertical"
            labelCol={{
              span: 30,
            }}
            wrapperCol={{
              span: 20,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              layout="vertical"
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}
            >
              <Input size="large"/>
            </Form.Item>

            <Form.Item
              layout="vertical"
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password  size="large"/>
            </Form.Item>

            <Form.Item
              name="remember"
              valuePropName="checked"
            >
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="full-width" size="large">
                Login
              </Button>
            </Form.Item>
          </Form>
          <div className="copyright">
            <label className='pt-name'>IPR</label>
          </div>
        </div>
        <div className="right-column">
          <div className="image-slider">
            <img className="active" src={background} alt="background" />
          </div>
        </div>
      </Flex>
    </Context.Provider>
  )
}

export default Login;