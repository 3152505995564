import './Role.scss';
import MasterLayout from '../../components/MasterLayout';
import Spinner from '../../components/Spinner';
import React, { useEffect, useState } from 'react';
import { 
  Flex, 
  Select, 
  Space, 
  Input, 
  Button,
  Table,
  Modal,
  Form,
  notification,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import * as userApi from '../../stores/business/UserApi';
import * as roleApi from '../../stores/business/RoleApi';
import * as userRoleApi from '../../stores/business/UserRoleApi';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const Context = React.createContext({
  name: 'Default',
});

const { confirm } = Modal;

const Content = () => {
  const [api, contextHolder] = notification.useNotification();
  const [filterOptionSelected, setFilterOptionSelected] = useState('roleName');
  const [filterSearchValue, setFilterSearchValue] = useState('');
  const [isOpenModalAssign, setIsOpenModalAssign] = useState(false);
  const [form] = Form.useForm();
  const nav = useNavigate();
  const dispatch = useDispatch();

  const tableColumns = [
    {
      title: 'Role',
      dataIndex: 'roleName',
      key: 'roleName',
      sorter: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button shape="circle" icon={<EditOutlined />} onClick={() => {setSelectedItem(record);form.setFieldsValue(record);setIsOpenModalAssign(true)}}/>
        </Space>
      ),
    },
  ];

  const [userAssignedList, setUserAssignedList] = useState();
  const tableColumnsUserAssigned = [
    {
      title: 'User Name',
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: true,
      width: '70%',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '30%',
      render: (_, record) => (
        <Space size="middle">
          <Button shape="circle" icon={<DeleteOutlined />} onClick={() => {showDeleteConfirm(record);}}/>
        </Space>
      ),
    },
  ];

  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Are you sure delete this user assignment?',
      icon: <ExclamationCircleFilled />,
      content: 'You can\'t undo this operation when finished!',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk() {
        deleteData(record);
      },
    });
  };

  const openNotification = (message, description, type) => {
    if (type === "error") {
      api.error({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    } else if (type === "success") {
      api.success({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    }
  };

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingUserAssigned, setLoadingUserAssigned] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: "roleName",
    sortOrder: "ascend",
  });
  const [tableAssignedParams, setTableAssignedParams] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
    sortField: "fullName",
    sortOrder: "ascend",
  });
  const [userHasNoRole, setUserHasNoRole] = useState();

  const filterOptions = [
    {value: 'roleName', label: 'Role'},
  ];

  const handleChangeOption = (value) => {
    setFilterOptionSelected(value);
  };

  const handleChangeValue = (e) => {
    setFilterSearchValue(e.target.value);
  };

  const fetchData = async () => {
    setLoading(true);

    var sortField = tableParams.sortField === undefined ? "roleName" : tableParams.sortField;
    var sortOrder = tableParams.sortOrder === "ascend" ? "asc" : tableParams.sortOrder === "descend" ? "desc" : "asc"

    const response = await dispatch(roleApi.getRolePage({
      "page": tableParams.pagination.current,
      "size": tableParams.pagination.pageSize,
      "sort": [
        sortField + " " + sortOrder
      ],
      "search": [
        {
          "field": filterOptionSelected,
          "value": filterSearchValue
        }
      ]
    }));
    if (response.status === 200) {
      setData(response.data.contents);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response.data.totalData,
        }
      });
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/role")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setLoading(false);
  };

  const fetchDataUserHasNoRole = async () => {
    var selectedRole = selectedItem?.roleCode === undefined ? "ADMIN_APPLICATION" : selectedItem?.roleCode;
    const response = await dispatch(userApi.getUserHasNoRole(selectedRole, {
      "sort": [
        "fullName asc"
      ],
      "search": [
        {
          "field": "fullName",
          "value": ""
        }
      ]
    }));
    if (response.status === 200) {
      setUserHasNoRole(response.data.map(user => ({
        label: user.fullName,
        value: user.userId
      })));
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/role")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setLoading(false);
  };

  const fetchDataUserAssigned = async () => {
    setLoadingUserAssigned(true);

    var sortField = tableAssignedParams.sortField === undefined ? "fullName" : tableAssignedParams.sortField;
    var sortOrder = tableAssignedParams.sortOrder === "ascend" ? "asc" : tableAssignedParams.sortOrder === "descend" ? "desc" : "asc"
    if (selectedItem === undefined || selectedItem.roleCode === undefined) {
      setLoadingUserAssigned(false);
      return;
    }
    const response = await dispatch(userApi.getUserHasRole(selectedItem?.roleCode, {
      "page": tableAssignedParams.pagination.current,
      "size": tableAssignedParams.pagination.pageSize,
      "sort": [
        sortField + " " + sortOrder
      ],
      "search": [
        {
          "field": "fullName",
          "value": ""
        }
      ]
    }));
    if (response.status === 200) {
      setUserAssignedList(response.data.contents);
      setTableAssignedParams({
        ...tableAssignedParams,
        pagination: {
          ...tableAssignedParams.pagination,
          total: response.data.totalData,
        }
      });
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/role")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setLoadingUserAssigned(false);
  };

  const deleteData = async (record) => {
    setLoadingUserAssigned(true);
    const response = await dispatch(userRoleApi.removeUserRole({
      roleCode: selectedItem.roleCode,
      userIds: [
        record.userId
      ]
    }));
    if (response.status === 200) {
      if (tableAssignedParams.pagination.current === 1) {
        fetchDataUserAssigned();
      } else {
        setTableAssignedParams({
          ...tableAssignedParams,
          pagination: {
            ...tableAssignedParams.pagination,
            current: 1,
          }
        });
      }
      fetchDataUserHasNoRole();
      openNotification('Success', 'Success to remove assignment user!', 'success');
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/role")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setLoadingUserAssigned(false);
  };

  useEffect(() => {
    fetchData();
  }, [
    filterOptionSelected,
    filterSearchValue,
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
  ]);

  useEffect(() => {
    fetchDataUserAssigned();
  }, [
    tableAssignedParams.pagination?.current,
    tableAssignedParams.pagination?.pageSize,
    tableAssignedParams?.sortOrder,
    tableAssignedParams?.sortField,
  ]);

  const handleTableChange = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableParams({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleTableAssignedChange = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableAssignedParams({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });

    if (pagination.pageSize !== tableAssignedParams.pagination?.pageSize) {
      setUserAssignedList([]);
    }
  };

  const onFinish = async (values) => {
    const response = await dispatch(userRoleApi.assignUserRole({
      roleCode: selectedItem.roleCode,
      userIds: [
        values.userId
      ]
    }));
    if (response.status === 200) {
      openNotification('Success', 'Success to assign user!', 'success');
      fetchDataUserHasNoRole();
      fetchDataUserAssigned();
      form.resetFields();
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/role")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
      setIsOpenModalAssign(true);
    }
  };

  const onCancel = () => {
    form.resetFields();
    setIsOpenModalAssign(false);
    setTableAssignedParams({
      ...tableAssignedParams,
      pagination: {
        ...tableAssignedParams.pagination,
        current: 1,
      }
    });
  };

  const [selectedItem, setSelectedItem] = useState();

  useEffect(() => {
    fetchDataUserHasNoRole();
    fetchDataUserAssigned();
  }, [selectedItem])

  return (
    <Context.Provider value={true}>
      {contextHolder}
      <Flex gap="middle" vertical={false}>
        <Space>
          <Select
            size="large"
            defaultValue={filterOptionSelected}
            onChange={handleChangeOption}
            style={{ width: 200 }}
            options={filterOptions}
          />
          <Input 
            size="large"
            style={{ width: 300 }}
            onChange={handleChangeValue}
            value={filterSearchValue} />
        </Space>
      </Flex>
      <Flex gap="middle" vertical style={{marginTop: '24px'}}>
        <Table
          columns={tableColumns}
          rowKey={(record) => record.roleCode}
          dataSource={data}
          pagination={tableParams.pagination}
          loading={{
            spinning: loading,
            indicator: <Spinner className="spinner"/>
          }}
          onChange={handleTableChange}
        />
      </Flex>
      <Modal
        title="Assign Role"
        open={isOpenModalAssign}
        width={700}
        closable={false}
        footer={[]}
      >
        <Form
          form={form}
          initialValues={selectedItem}
          name="assign-role-form"
          className="assign-role-form"
          layout="vertical"
          autoComplete="off"
          autoFocus={true}
          onFinish={onFinish}
        >
            
          <Form.Item
            layout="vertical"
            label="Role Name"
            name="roleName"
          >
            <Input  size="large" disabled/>
          </Form.Item>
          <Flex gap="middle" vertical style={{marginTop: '24px'}}>
            <Table
              columns={tableColumnsUserAssigned}
              rowKey={(record) => record.userId}
              dataSource={userAssignedList}
              pagination={tableAssignedParams.pagination}
              loading={{
                spinning: loadingUserAssigned,
                indicator: <Spinner className="spinner"/>
              }}
              onChange={handleTableAssignedChange}
            />
          </Flex>
          <Space>
            <Form.Item
              layout="vertical"
              label="User Name"
              name="userId"
              hasFeedback
              style={{width: '250px'}}
              rules={[
                {
                  required: true,
                  message: 'Please select a user!',
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a user"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={userHasNoRole}
                size="large"
              />
            </Form.Item>

            <Button 
              type="primary" 
              htmlType="submit" 
              size="large"
              style={{marginTop:'6px'}}
            >
              Assign User
            </Button>
          </Space>

          <Space
              align='end'
              style={{ flexBasis: '100%', flexDirection: 'column-reverse', width: '100%', marginTop: '28px', }}
            >
              <Form.Item>
                <Button htmlType="button" size="large" style={{width: '150px'}} onClick={onCancel}> 
                  Close
                </Button>
              </Form.Item>
            </Space>
        </Form>
      </Modal>
    </Context.Provider>
  )
}

const Role = () => {
  return (
    <MasterLayout
      pageName="Role"
      activeMenu="master-role" 
      activeParent="master"
      content={Content()}
    />
  )
}

export default Role;