import React, { useEffect, useState } from 'react';
import './PO.scss';
import MasterLayout from '../../components/MasterLayout';
import Spinner from '../../components/Spinner';
import dayjs from 'dayjs';
import { 
  Flex, 
  Select, 
  Space, 
  Input, 
  InputNumber, 
  Button,
  Table,
  Modal,
  Form,
  notification,
  DatePicker,
  Divider,
  Image,
  QRCode,
} from 'antd';
import {
  EditOutlined,
  EyeOutlined,
  PictureOutlined,
  PrinterOutlined,
  QrcodeOutlined,
} from '@ant-design/icons';
import * as poApi from '../../stores/business/PoApi';
import * as customerApi from '../../stores/business/CustomerApi';
import * as merekApi from '../../stores/business/MerekApi';
import * as pakaianApi from '../../stores/business/PakaianApi';
import * as stokBahanApi from '../../stores/business/StokBahanApi';
import * as masterDataApi from '../../stores/business/MasterDataApi';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import noImage from '../../assets/no-image.png';

const Context = React.createContext({
  name: 'Default',
});

const Content = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const nav = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [formStok] = Form.useForm();
  const [formGambar] = Form.useForm();

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (message, description, type) => {
    if (type === "error") {
      api.error({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    } else if (type === "success") {
      api.success({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    }
  };

  const [disableTukangPotong, setDisableTukangPotong] = useState(true);
  const [disableSablon, setDisableSablon] = useState(true);
  const [disableBordir, setDisableBordir] = useState(true);
  const [disableCmt, setDisableCmt] = useState(true);
  const [disableCmtCelana, setDisableCmtCelana] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingStok, setLoadingStok] = useState(false);
  const [loadingGambar, setLoadingGambar] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalStok, setIsOpenModalStok] = useState(false);
  const [isOpenModalGambar, setIsOpenModalGambar] = useState(false);
  const [isOpenModalQr, setIsOpenModalQr] = useState(false);
  const [mode, setMode] = useState();
  const [data, setData] = useState();
  const [dataStok, setDataStok] = useState();
  const [dataGambar, setDataGambar] = useState([]);
  const [gambarSelectedId, setGambarSelectedId] = useState("");
  const [qrData, setQrData] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filterOptionSelected, setFilterOptionSelected] = useState('noPo');
  const [filterSearchValue, setFilterSearchValue] = useState('');
  const [tokoOpt, setTokoOpt] = useState([]);
  const [merekOpt, setMerekOpt] = useState([]);
  const [jenisPakaianOpt, setJenisPakaianOpt] = useState([]);
  const [namaPakaianOpt, setNamaPakaianOpt] = useState([]);
  const [variasiOpt, setVariasiOpt] = useState([]);
  const [aksesorisOpt, setAksesorisOpt] = useState([]);
  const [poGambarOpt, setPoGambarOpt] = useState([]);
  const [previewImages, setPreviewImages] = useState({
    items: [],
    visible: false,
  });
  const [selectedData, setSelectedData] = useState({
    id: null,
    noPo: "",
    namaToko: "",
    merek: "",
    tanggalPotong: "",
    tanggalPotongStr: "",
    tanggalPotongAnt: null,
    jenisPakaian: "",
    namaPakaian: "",
    statusProduksi: "",
    statusAdministrasi: "",
    statusPo: "",
    createdAt: null,
    isTukangPotongDone: false,
    isDiserahkanTukangPotong: false,
    namaTukangPotong: "",
    tglDiserahkanTukangPotong: "",
    tglDiserahkanTukangPotongAnt: null,
    isTukangSablonDone: false,
    isDiperlukanTukangSablon: false,
    isDiserahkanTukangSablon: false,
    namaTukangSablon: "",
    tglDiserahkanTukangSablon: "",
    tglDiserahkanTukangSablonAnt: null,
    isTukangBordirDone: false,
    isDiperlukanTukangBordir: false,
    isDiserahkanTukangBordir: false,
    namaTukangBordir: "",
    tglDiserahkanTukangBordir: "",
    tglDiserahkanTukangBordirAnt: null,
    isTukangCmtDone: false,
    isDiserahkanTukangCmt: false,
    namaTukangCmt: "",
    tglDiserahkanTukangCmt: "",
    tglDiserahkanTukangCmtAnt: null,
    isTukangCmtCelanaDone: false,
    isDiperlukanTukangCmtCelana: false,
    isDiserahkanTukangCmtCelana: false,
    namaTukangCmtCelana: "",
    tglDiserahkanTukangCmtCelana: "",
    tglDiserahkanTukangCmtCelanaAnt: null,
    isPicDone: false,
    isDiserahkanPic: false,
    namaPic: "",
    tglDiserahkanPic: "",
    tglDiserahkanPicAnt: null,
    totalBeratKain: null,
    images: [],
    totalLusin: null,
    totalPieceRusak: null,
    totalKeseluruhan: null,
  });
  const [selectedDataStok, setSelectedDataStok] = useState({
    id: null,
    poId: null,
    jenisBahan: null,
    warnaBahan: null,
    ketebalanBahan: null,
    settingBahan: null,
    jenisSettingBahan: null,
    beratKain: null,
  });
  const [selectedDataGambar, setSelectedDataGambar] = useState({
    id: null,
    poId: null,
    kodeGambar: null,
    urlGambar: null,
    namaGambar: null,
    variasi: [],
    aksesoris: [],
    jumlahLusin: null,
    sizes: [
      {
        id: 0,
        poId: 0,
        poGambarId: 0,
        ukuran: "",
        jumlah: 0,
        kerusakanTukangPotong: 0,
        kerusakanSablon: 0,
        kerusakanBordir: 0,
        kerusakanCmt: 0,
        kerusakanCmtCelana: 0
      }
    ]
  });
  const [filterDropdownMasterBahan, setFilterDropdownMasterBahan] = useState([
    {
      field: "jenis",
      value: ""
    },
    {
      field: "ketebalan",
      value: ""
    },
    {
      field: "gramasi",
      value: ""
    },
    {
      field: "warna",
      value: ""
    },
    {
      field: "setting",
      value: ""
    }
  ]);
  const [dropdownJenisBahan, setDropdownJenisBahan] = useState();
  const [dropdownKetebalanBahan, setDropdownKetebalanBahan] = useState();
  const [dropdownGramasiBahan, setDropdownGramasiBahan] = useState();
  const [dropdownWarnaBahan, setDropdownWarnaBahan] = useState();
  const [dropdownSettingBahan, setDropdownSettingBahan] = useState();

  const filterOptions = [
    {value: 'noPo', label: 'No. Purchase Order (PO)'},
    {value: 'tanggalPotongStr', label: 'Tanggal Potong'},
    {value: 'statusProduksi', label: 'Status Produksi'},
    {value: 'statusAdministrasi', label: 'Status Administrasi'},
    {value: 'statusPo', label: 'Status Purchase Order (PO)'},
  ];

  const poParafStatus = [
    {value: 'Selesai', label: 'Selesai'},
    {value: 'Rusak', label: 'Rusak'},
  ];
  
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: "id",
    sortOrder: "descend",
  });

  const tableColumns = [
    {
      title: 'No. Purchase Order (PO)',
      dataIndex: 'noPo',
      key: 'noPo',
      sorter: true,
    },
    {
      title: 'Tanggal Potong',
      dataIndex: 'tanggalPotongStr',
      key: 'tanggalPotongStr',
      sorter: true,
    },
    {
      title: 'Status Produksi',
      dataIndex: 'statusProduksi',
      key: 'statusProduksi',
      sorter: true,
      render: (_, record) => (
        record.statusProduksi === null ? "-" : record.statusProduksi
      ),
    },
    {
      title: 'Status Administrasi',
      dataIndex: 'statusAdministrasi',
      key: 'statusAdministrasi',
      sorter: true,
      render: (_, record) => (
        record.statusAdministrasi === null ? "-" : record.statusAdministrasi
      ),
    },
    {
      title: 'Status Purchase Order (PO)',
      dataIndex: 'statusPo',
      key: 'statusPo',
      sorter: true,
      render: (_, record) => (
        record.statusPo === null ? "-" : record.statusPo
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          {record.statusProduksi !== "Submitted" && <Button shape="circle" icon={<QrcodeOutlined />} onClick={() => {showQr(record);}}/>}
          {record.images?.length > 0 && <Button shape="circle" icon={<PictureOutlined />} onClick={() => {openPreviewImages(record);}}/>}
          {record.statusProduksi !== "Submitted" && (record.statusAdministrasi !== "Done" || record.statusAdministrasi === null) && <Button shape="circle" icon={<EditOutlined />} onClick={() => {editPo(record);}}/>}
          {(record.statusAdministrasi === "Done" || record.statusProduksi === "Submitted") && <Button shape="circle" icon={<EyeOutlined />} onClick={() => {viewPo(record);}}/>}
        </Space>
      ),
    },
  ];
  
  const [tableParamsStok, setTableParamsStok] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
    sortField: "id",
    sortOrder: "descend",
  });

  const tableColumnsStok = [
    {
      title: 'Jenis Bahan',
      dataIndex: 'jenisBahan',
      key: 'jenisBahan',
      sorter: true,
    },
    {
      title: 'Warna Bahan',
      dataIndex: 'warnaBahan',
      key: 'warnaBahan',
      sorter: true,
    },
    {
      title: 'Ketebalan Bahan',
      dataIndex: 'ketebalanBahan',
      key: 'ketebalanBahan',
      sorter: true,
      render: (_, record) => (
        record.ketebalanBahan + "s"
      ),
    },
    {
      title: 'Setting Bahan',
      dataIndex: 'settingBahan',
      key: 'settingBahan',
      sorter: true,
    },
    {
      title: 'Jenis Setting Bahan',
      dataIndex: 'jenisSettingBahan',
      key: 'jenisSettingBahan',
      sorter: true,
    },
    {
      title: 'Kg',
      dataIndex: 'beratKain',
      key: 'beratKain',
      sorter: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button shape="circle" icon={selectedData.statusProduksi === "Draft" ? <EditOutlined /> : <EyeOutlined />} onClick={() => {onEditStok(record)}}/>
        </Space>
      ),
    },
  ];
  
  const [tableParamsGambar, setTableParamsGambar] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
    sortField: "id",
    sortOrder: "descend",
  });

  const tableColumnsGambar = [
    {
      title: 'Kode Gambar',
      dataIndex: 'kodeGambar',
      key: 'kodeGambar',
      sorter: true,
      render: (_, record) => (
        record.kodeGambar === null ? "NO IMAGE" : record.kodeGambar
      ),
    },
    {
      title: 'Nama Gambar',
      dataIndex: 'namaGambar',
      key: 'namaGambar',
      sorter: true,
      render: (_, record) => (
        record.namaGambar === null ? "NO IMAGE" : record.namaGambar
      ),
    },
    {
      title: 'Total Jumlah Lusin',
      dataIndex: 'jumlahLusin',
      key: 'jumlahLusin',
      sorter: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button shape="circle" icon={selectedData.statusProduksi === "Draft" ? <EditOutlined /> : <EyeOutlined />} onClick={() => {onEditGambar(record)}}/>
        </Space>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableParams({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });
    
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleTableChangeStok = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableParamsStok({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });
    
    if (pagination.pageSize !== tableParamsStok.pagination?.pageSize) {
      setDataStok([]);
    }
  };

  const handleTableChangeGambar = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableParamsGambar({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });
    
    if (pagination.pageSize !== tableParamsGambar.pagination?.pageSize) {
      setDataGambar([]);
    }
  };

  const handleChangeOption = (value) => {
    setFilterOptionSelected(value);
  };

  const handleChangeValue = (e) => {
    setFilterSearchValue(e.target.value);
  };

  const fetchData = async () => {
    setLoading(true);

    var sortField = tableParams.sortField === undefined ? "id" : tableParams.sortField;
    var sortOrder = tableParams.sortOrder === "ascend" ? "asc" : "desc"

    const response = await dispatch(poApi.getPoPageAdministration({
      "page": tableParams.pagination.current,
      "size": tableParams.pagination.pageSize,
      "sort": [
        sortField + " " + sortOrder,
      ],
      "search": [
        {
          field: filterOptionSelected,
          value: filterSearchValue
        }
      ]
    }));
    if (response.status === 200) {
      setData(response.data.contents);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response.data.totalData,
        }
      });
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoading(false);
  };

  const fetchDataStok = async () => {
    setLoadingStok(true);
    if (selectedData.id == null) {
      setLoadingStok(false);
      return;
    }

    var sortField = tableParamsStok.sortField === undefined ? "id" : tableParamsStok.sortField;
    var sortOrder = tableParamsStok.sortOrder === "ascend" ? "asc" : "desc"

    const response = await dispatch(poApi.getPoPageStok({
      "page": tableParamsStok.pagination.current,
      "size": tableParamsStok.pagination.pageSize,
      "sort": [
        sortField + " " + sortOrder,
      ],
      "search": [
        {
          field: "poId",
          value: selectedData.id
        }
      ]
    }));
    if (response.status === 200) {
      setDataStok(response.data.contents);
      setTableParamsStok({
        ...tableParamsStok,
        pagination: {
          ...tableParamsStok.pagination,
          total: response.data.totalData,
        }
      });
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoadingStok(false);
  };

  const fetchStokCalculation = async () => {
    if (selectedData.id == null) {
      return;
    }

    const response = await dispatch(poApi.getStokCalculation(selectedData.id));
    if (response.status === 200) {
      form.setFieldValue('totalBeratKain', response.data);
      setSelectedData({
        ...selectedData,
        totalBeratKain: response.data,
      });
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchTotalLusin = async () => {
    if (selectedData.id == null) {
      return;
    }

    const response = await dispatch(poApi.getTotalLusin(selectedData.id));
    if (response.status === 200) {
      form.setFieldValue('totalLusin', response.data);
      setSelectedData({
        ...selectedData,
        totalLusin: response.data,
      });
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchToko = async () => {
    const response = await dispatch(customerApi.getTokoList());
    if (response.status === 200) {
      setTokoOpt(response.data);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchMerek = async () => {
    const response = await dispatch(merekApi.getMerekList());
    if (response.status === 200) {
      setMerekOpt(response.data);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchJenisPakaian = async () => {
    const response = await dispatch(pakaianApi.getJenisPakaianList());
    if (response.status === 200) {
      setJenisPakaianOpt(response.data);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchNamaPakaian = async (jenis) => {
    const response = await dispatch(pakaianApi.getNamaPakaianList(jenis));
    if (response.status === 200) {
      setNamaPakaianOpt(response.data);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchDropdown = async (tipe) => {
    let list = filterDropdownMasterBahan;
    list = list.map(obj => {
      if (obj.field === "ketebalan") {
        obj.value = obj.value ? obj.value.replace("s", "") : "";
      }
      return obj;
    });
    const response = await dispatch(stokBahanApi.getDropdownStokBahan(tipe, list));
    if (response.status === 200) {
      if (tipe === "jenis-bahan") {
        setDropdownJenisBahan(response.data);
      } else if (tipe === "ketebalan-bahan") {
        setDropdownKetebalanBahan(response.data);
      } else if (tipe === "gramasi") {
        setDropdownGramasiBahan(response.data);
      } else if (tipe === "warna-bahan") {
        setDropdownWarnaBahan(response.data);
      } else if (tipe === "setting-bahan") {
        setDropdownSettingBahan(response.data);
      } 
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchAksesoris = async () => {
    const response = await dispatch(masterDataApi.getAksesoris());
    if (response.status === 200) {
      setAksesorisOpt(response.data);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchVariasi = async () => {
    const response = await dispatch(masterDataApi.getVariasi());
    if (response.status === 200) {
      setVariasiOpt(response.data);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchPoGambarDropdown = async () => {
    if (selectedData.id == null) {
      return;
    }

    const response = await dispatch(poApi.getPoGambarDropdown(selectedData.id));
    if (response.status === 200) {
      setPoGambarOpt(response.data);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const resetForm = () => {
    form.resetFields();
    setSelectedData({
      id: null,
      noPo: "",
      namaToko: "",
      merek: "",
      tanggalPotong: "",
      tanggalPotongStr: "",
      tanggalPotongAnt: null,
      jenisPakaian: "",
      namaPakaian: "",
      statusProduksi: "",
      statusAdministrasi: "",
      statusPo: "",
      createdAt: null,
      isTukangPotongDone: false,
      isDiserahkanTukangPotong: false,
      namaTukangPotong: "",
      tglDiserahkanTukangPotong: "",
      tglDiserahkanTukangPotongAnt: null,
      isTukangSablonDone: false,
      isDiperlukanTukangSablon: false,
      isDiserahkanTukangSablon: false,
      namaTukangSablon: "",
      tglDiserahkanTukangSablon: "",
      tglDiserahkanTukangSablonAnt: null,
      isTukangBordirDone: false,
      isDiperlukanTukangBordir: false,
      isDiserahkanTukangBordir: false,
      namaTukangBordir: "",
      tglDiserahkanTukangBordir: "",
      tglDiserahkanTukangBordirAnt: null,
      isTukangCmtDone: false,
      isDiserahkanTukangCmt: false,
      namaTukangCmt: "",
      tglDiserahkanTukangCmt: "",
      tglDiserahkanTukangCmtAnt: null,
      isTukangCmtCelanaDone: false,
      isDiperlukanTukangCmtCelana: false,
      isDiserahkanTukangCmtCelana: false,
      namaTukangCmtCelana: "",
      tglDiserahkanTukangCmtCelana: "",
      tglDiserahkanTukangCmtCelanaAnt: null,
      isPicDone: false,
      isDiserahkanPic: false,
      namaPic: "",
      tglDiserahkanPic: "",
      tglDiserahkanPicAnt: null,
      totalBeratKain: null,
      images: [],
      totalLusin: null,
      totalPieceRusak: null,
      totalKeseluruhan: null,
    });
    form.setFieldsValue({
      id: null,
      noPo: "",
      namaToko: "",
      merek: "",
      tanggalPotong: "",
      tanggalPotongStr: "",
      tanggalPotongAnt: null,
      jenisPakaian: "",
      namaPakaian: "",
      statusProduksi: "",
      statusAdministrasi: "",
      statusPo: "",
      createdAt: null,
      isTukangPotongDone: false,
      isDiserahkanTukangPotong: false,
      namaTukangPotong: "",
      tglDiserahkanTukangPotong: "",
      tglDiserahkanTukangPotongAnt: null,
      isTukangSablonDone: false,
      isDiperlukanTukangSablon: false,
      isDiserahkanTukangSablon: false,
      namaTukangSablon: "",
      tglDiserahkanTukangSablon: "",
      tglDiserahkanTukangSablonAnt: null,
      isTukangBordirDone: false,
      isDiperlukanTukangBordir: false,
      isDiserahkanTukangBordir: false,
      namaTukangBordir: "",
      tglDiserahkanTukangBordir: "",
      tglDiserahkanTukangBordirAnt: null,
      isTukangCmtDone: false,
      isDiserahkanTukangCmt: false,
      namaTukangCmt: "",
      tglDiserahkanTukangCmt: "",
      tglDiserahkanTukangCmtAnt: null,
      isTukangCmtCelanaDone: false,
      isDiperlukanTukangCmtCelana: false,
      isDiserahkanTukangCmtCelana: false,
      namaTukangCmtCelana: "",
      tglDiserahkanTukangCmtCelana: "",
      tglDiserahkanTukangCmtCelanaAnt: null,
      isPicDone: false,
      isDiserahkanPic: false,
      namaPic: "",
      tglDiserahkanPic: "",
      tglDiserahkanPicAnt: null,
      totalBeratKain: null,
      images: [],
      totalLusin: null,
      totalPieceRusak: null,
      totalKeseluruhan: null,
    });
  }

  const resetFormGambar = () => {
    formGambar.resetFields();
    setSelectedDataGambar({
      id: null,
      poId: null,
      kodeGambar: null,
      urlGambar: null,
      namaGambar: null,
      variasi: [],
      aksesoris: [],
      jumlahLusin: null,
      sizes: [
        {
          id: 0,
          poId: 0,
          poGambarId: 0,
          ukuran: "",
          jumlah: 0,
          kerusakanTukangPotong: 0,
          kerusakanSablon: 0,
          kerusakanBordir: 0,
          kerusakanCmt: 0,
          kerusakanCmtCelana: 0
        }
      ]
    });
    formGambar.setFieldsValue({
      id: null,
      poId: null,
      kodeGambar: null,
      urlGambar: null,
      namaGambar: null,
      variasi: [],
      aksesoris: [],
      jumlahLusin: null,
      sizes: [
        {
          id: 0,
          poId: 0,
          poGambarId: 0,
          ukuran: "",
          jumlah: 0,
          kerusakanTukangPotong: 0,
          kerusakanSablon: 0,
          kerusakanBordir: 0,
          kerusakanCmt: 0,
          kerusakanCmtCelana: 0
        }
      ]
    });
  }

  const resetFormStok = () => {
    formStok.resetFields();
    setFilterDropdownMasterBahan([
      {
        field: "jenis",
        value: ""
      },
      {
        field: "ketebalan",
        value: ""
      },
      {
        field: "gramasi",
        value: ""
      },
      {
        field: "warna",
        value: ""
      },
      {
        field: "setting",
        value: ""
      }
    ]);
    setSelectedDataStok({
      id: null,
      poId: null,
      jenisBahan: null,
      warnaBahan: null,
      ketebalanBahan: null,
      settingBahan: null,
      jenisSettingBahan: null,
      beratKain: null,
    });
    formStok.setFieldsValue({
      id: null,
      poId: null,
      jenisBahan: null,
      warnaBahan: null,
      ketebalanBahan: null,
      settingBahan: null,
      jenisSettingBahan: null,
      beratKain: null,
    });
  }

  const editPo = (record) => {
    if (record.statusProduksi === "Draft") {
      setMode("Edit");
    } else {
      setMode("Paraf");
    }

    fetchNamaPakaian(record.jenisPakaian);

    record = {
      ...record,
      tanggalPotongAnt: dayjs(record.tanggalPotong),
      isTukangPotongDone: record.isDiserahkanTukangPotong,
      isTukangSablonDone: record.isDiserahkanTukangSablon,
      isTukangBordirDone: record.isDiserahkanTukangBordir,
      isTukangCmtDone: record.isDiserahkanTukangCmt,
      isTukangCmtCelanaDone: record.isDiserahkanTukangCmtCelana,
      isPicDone: record.isDiserahkanPic,
      tglDiserahkanTukangPotongAnt: record.tglDiserahkanTukangPotong ? dayjs(record.tglDiserahkanTukangPotong) : null,
      tglDiserahkanTukangBordirAnt: record.tglDiserahkanTukangBordir ? dayjs(record.tglDiserahkanTukangBordir) : null,
      tglDiserahkanTukangSablonAnt: record.tglDiserahkanTukangSablon ? dayjs(record.tglDiserahkanTukangSablon) : null,
      tglDiserahkanTukangCmtAnt: record.tglDiserahkanTukangCmt ? dayjs(record.tglDiserahkanTukangCmt) : null,
      tglDiserahkanTukangCmtCelanaAnt: record.tglDiserahkanTukangCmtCelana ? dayjs(record.tglDiserahkanTukangCmtCelana) : null,
      tglDiserahkanPicAnt: record.tglDiserahkanPic ? dayjs(record.tglDiserahkanPic) : null,
    }

    setSelectedData(record);
    form.setFieldsValue(record);
    setIsOpenModal(true);
  };

  const viewPo = (record) => {
    setMode("View");

    fetchNamaPakaian(record.jenisPakaian);

    record = {
      ...record,
      tanggalPotongAnt: dayjs(record.tanggalPotong),
      isTukangPotongDone: record.isDiserahkanTukangPotong,
      isTukangSablonDone: record.isDiserahkanTukangSablon,
      isTukangBordirDone: record.isDiserahkanTukangBordir,
      isTukangCmtDone: record.isDiserahkanTukangCmt,
      isTukangCmtCelanaDone: record.isDiserahkanTukangCmtCelana,
      isPicDone: record.isDiserahkanPic,
      tglDiserahkanTukangPotongAnt: record.tglDiserahkanTukangPotong ? dayjs(record.tglDiserahkanTukangPotong) : null,
      tglDiserahkanTukangBordirAnt: record.tglDiserahkanTukangBordir ? dayjs(record.tglDiserahkanTukangBordir) : null,
      tglDiserahkanTukangSablonAnt: record.tglDiserahkanTukangSablon ? dayjs(record.tglDiserahkanTukangSablon) : null,
      tglDiserahkanTukangCmtAnt: record.tglDiserahkanTukangCmt ? dayjs(record.tglDiserahkanTukangCmt) : null,
      tglDiserahkanTukangCmtCelanaAnt: record.tglDiserahkanTukangCmtCelana ? dayjs(record.tglDiserahkanTukangCmtCelana) : null,
      tglDiserahkanPicAnt: record.tglDiserahkanPic ? dayjs(record.tglDiserahkanPic) : null,
    }

    setSelectedData(record);
    form.setFieldsValue(record);
    setIsOpenModal(true);
  };

  const openPreviewImages = (record) => {
    let urls = [];
    record.images.map(obj => {
      let result = BASE_URL + obj;
      urls.push(result);
      return obj;
    });
    setPreviewImages({
      ...previewImages,
      visible: true,
      items: urls,
    });
  }

  const showQr = (record) => {
    let baseUrlFe = window.location.href.replace("/po/administrasi", "/stok/gudang");
    setQrData(baseUrlFe + "/mobile/poId/" + record.id);
    setSelectedData({
      ...record,
      settingBahan: record.settingBahan + " " + record.jenisSettingBahan,
    });
    form.setFieldsValue({
      ...record,
      settingBahan: record.settingBahan + " " + record.jenisSettingBahan,
    });

    setIsOpenModalQr(true);
  }

  const onEditStok = (record) => {
    setSelectedDataStok({
      ...record,
      settingBahan: record.settingBahan + " " + record.jenisSettingBahan,
    });
    formStok.setFieldsValue({
      ...record,
      settingBahan: record.settingBahan + " " + record.jenisSettingBahan,
    });
    setIsOpenModalStok(true);
  }

  const onEditGambar = (record) => {
    setSelectedDataGambar({
      ...record,
      aksesoris: record.aksesoris !== null && record.aksesoris !== "" ? record.aksesoris.split("$0$") : null,
      variasi: record.variasi !== null && record.variasi !== "" ? record.variasi.split("$0$") : null,
    });
    formGambar.setFieldsValue({
      ...record,
      aksesoris: record.aksesoris !== null && record.aksesoris !== "" ? record.aksesoris.split("$0$") : null,
      variasi: record.variasi !== null && record.variasi !== "" ? record.variasi.split("$0$") : null,
    });
    setIsOpenModalGambar(true);
  }

  const onCancel = () => {
    resetForm();
    fetchData();
    setGambarSelectedId("");
    setIsOpenModal(false);
  };

  const onCancelStok = () => {
    resetFormStok();
    fetchDataStok();
    setIsOpenModalStok(false);
    fetchStokCalculation();
  };

  const onFinish = (values) => {
    parafPo(selectedData.id, dataGambar);
  };

  const parafPo = async (id, payload) => {
    if (!selectedData.init) {
      setIsOpenModal(false);
    }
    const response = await dispatch(poApi.paraf(id, payload));
    if (response.status === 200) {
      if (selectedData.init === true) {
        setSelectedData({
          ...selectedData,
          init: false,
        });
      } else {
        setDataGambar([]);
        resetForm();
        fetchData();
        setGambarSelectedId("");
      }
      openNotification('Success', 'Success to paraf po!', 'success');
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=stok/bahan")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
      setIsOpenModal(true);
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
      setIsOpenModal(true);
    }
  }

  const fetchPoGambar = async () => {
    setLoadingGambar(true);
    if (selectedData.id == null) {
      setLoadingGambar(false);
      return;
    }

    var sortField = tableParamsGambar.sortField === undefined ? "id" : tableParamsGambar.sortField;
    var sortOrder = tableParamsGambar.sortOrder === "ascend" ? "asc" : "desc"

    const response = await dispatch(poApi.getPoPageGambar({
      "page": tableParamsGambar.pagination.current,
      "size": tableParamsGambar.pagination.pageSize,
      "sort": [
        sortField + " " + sortOrder,
      ],
      "search": [
        {
          field: "poId",
          value: selectedData.id
        }
      ]
    }));
    if (response.status === 200) {
      let data = response.data.contents;
      for (let i = 0; i < data.length; i++) {
        if (data[i].statusTukangPotong !== null) {
          data[i].isStatusTukangPotongDone = true;
        } else {
          data[i].isStatusTukangPotongDone = false;
        }
        if (data[i].statusSablon !== null) {
          data[i].isStatusSablonDone = true;
        } else {
          data[i].isStatusSablonDone = false;
        }
        if (data[i].statusBordir !== null) {
          data[i].isStatusBordirDone = true;
        } else {
          data[i].isStatusBordirDone = false;
        }
        if (data[i].statusCmt !== null) {
          data[i].isStatusCmtDone = true;
        } else {
          data[i].isStatusCmtDone = false;
        }
        if (data[i].statusCmtCelana !== null) {
          data[i].isStatusCmtCelanaDone = true;
        } else {
          data[i].isStatusCmtCelanaDone = false;
        }
      }

      setDataGambar(data);
      setTableParamsGambar({
        ...tableParamsGambar,
        pagination: {
          ...tableParamsGambar.pagination,
          total: response.data.totalData,
        }
      });
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=po/produksi")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoadingGambar(false);
  }

  const onChangeDropdown = (tipe, value) => {
    let list = filterDropdownMasterBahan;
    if (tipe === "jenis-bahan") {
      list = list.map(obj => {
        if (obj.field === "jenis") {
          obj.value = value ? value : "";
        }
        return obj;
      });
    } else if (tipe === "ketebalan-bahan") {
      list = list.map(obj => {
        if (obj.field === "ketebalan") {
          obj.value = value ? value : "";
        }
        return obj;
      });
    } else if (tipe === "gramasi") {
      list = list.map(obj => {
        if (obj.field === "gramasi") {
          obj.value = value ? value : "";
        }
        return obj;
      });
    } else if (tipe === "warna-bahan") {
      list = list.map(obj => {
        if (obj.field === "warna") {
          obj.value = value ? value : "";
        }
        return obj;
      });
    } else if (tipe === "setting-bahan") {
      list = list.map(obj => {
        if (obj.field === "setting") {
          obj.value = value ? value : "";
        }
        return obj;
      });
    } 

    setFilterDropdownMasterBahan(list);
  }

  const print = () => {
    let baseUrlFe = window.location.href.replace("/po/administrasi", "/stok/gudang");
    let urlFe = baseUrlFe + "/mobile/poId/";

    var ids = selectedRowKeys.map(obj => obj).join(',');
    let encodedId = btoa(ids);
    setSelectedRowKeys([]);
    window.open(BASE_URL + "/v1/generate/qr/po?url=" + urlFe + "&ids=" + encodedId);
  };

  const onPrint = () => {
    let baseUrlFe = window.location.href.replace("/po/administrasi", "/stok/gudang");
    let urlFe = baseUrlFe + "/mobile/poId/";
    let encodedId = btoa(selectedData.id);
    window.open(BASE_URL + "/v1/generate/qr/po?url=" + urlFe + "&ids=" + encodedId);
  }

  const onClose = () => {
    resetForm();
    setQrData();
    setIsOpenModalQr(false);
  }

  const onSelectTableChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectTableChange,
  };

  const calculateDataPo = () => {
    let totalLusin = 0;
    let totalPieceRusak = 0;
    let totalLusinKeseluruhan = 0;
    let totalPieceRusakKeseluruhan = 0;

    for (let i = 0; i < dataGambar.length; i++) {
      totalLusinKeseluruhan += dataGambar[i].jumlahLusin;
      if (dataGambar[i].id == gambarSelectedId) {
        totalLusin += dataGambar[i].jumlahLusin;
      }

      for (let j = 0; j < dataGambar[i].sizes.length; j++) {
        let kerusakanTukangPotong = dataGambar[i].sizes[j].kerusakanTukangPotong === null ? 0 : dataGambar[i].sizes[j].kerusakanTukangPotong;
        let kerusakanSablon = dataGambar[i].sizes[j].kerusakanSablon === null ? 0 : dataGambar[i].sizes[j].kerusakanSablon;
        let kerusakanBordir = dataGambar[i].sizes[j].kerusakanBordir === null ? 0 : dataGambar[i].sizes[j].kerusakanBordir;
        let kerusakanCmt = dataGambar[i].sizes[j].kerusakanCmt === null ? 0 : dataGambar[i].sizes[j].kerusakanCmt;
        let kerusakanCmtCelana = dataGambar[i].sizes[j].kerusakanCmtCelana === null ? 0 : dataGambar[i].sizes[j].kerusakanCmtCelana;
        totalPieceRusakKeseluruhan += kerusakanTukangPotong + kerusakanSablon + kerusakanBordir + kerusakanCmt + kerusakanCmtCelana;

        if (dataGambar[i].id == gambarSelectedId) {
          totalPieceRusak += kerusakanTukangPotong + kerusakanSablon + kerusakanBordir + kerusakanCmt + kerusakanCmtCelana;
        }
      }
    }

    let totalPieceCalculationItem = (totalLusin * 12) - totalPieceRusak;
    let totalPieceCalculationRemainItem = totalPieceCalculationItem % 12;
    let totalLusinCalculationItem = Math.floor(totalPieceCalculationItem / 12) + (totalPieceCalculationRemainItem > 0 ? " + (" + totalPieceCalculationRemainItem + " pieces)" : "");

    let totalPieceCalculationAll = (totalLusinKeseluruhan * 12) - totalPieceRusakKeseluruhan;
    let totalPieceCalculationRemainAll = totalPieceCalculationAll % 12;
    let totalLusinCalculationAll = Math.floor(totalPieceCalculationAll / 12) + (totalPieceCalculationRemainAll > 0 ? " + (" + totalPieceCalculationRemainAll + " pieces)" : "");

    form.setFieldValue('totalLusinGambar', totalLusinCalculationItem);
    form.setFieldValue('totalPieceGambar', totalPieceCalculationItem);
    form.setFieldValue('totalLusinGambarKeseluruhan', totalLusinCalculationAll);
    form.setFieldValue('totalPieceGambarKeseluruhan', totalPieceCalculationAll);

    if (totalPieceCalculationItem < 0 || totalPieceCalculationAll < 0) {
      openNotification('Error', 'Total piece / total piece keseluruhan tidak boleh kurang dari 0', 'error');
    }
  }

  const changeValue = (id, type, value) => {
    let newData = dataGambar;

    for (let i = 0; i < newData.length; i++) {
      for (let j = 0; j < newData[i].sizes.length; j++) {
        if (newData[i].sizes[j].id == id) {
          if (type === "tukang-potong") {
            newData[i].sizes[j].kerusakanTukangPotong = value;
          } else if (type === "sablon") {
            newData[i].sizes[j].kerusakanSablon = value;
          } else if (type === "bordir") {
            newData[i].sizes[j].kerusakanBordir = value;
          } else if (type === "cmt") {
            newData[i].sizes[j].kerusakanCmt = value;
          } else if (type === "cmt-celana") {
            newData[i].sizes[j].kerusakanCmtCelana = value;
          }
        }
      }
    }

    setDataGambar(newData);
    calculateDataPo();
  }

  const changeValueStatus = (type, value) => {
    let newData = dataGambar;

    for (let i = 0; i < newData.length; i++) {
      if (newData[i].id == gambarSelectedId) {
        if (type === "tukang-potong") {
          newData[i].statusTukangPotong = value;
        } else if (type === "sablon") {
          newData[i].statusSablon = value;
        } else if (type === "bordir") {
          newData[i].statusBordir = value;
        } else if (type === "cmt") {
          newData[i].statusCmt = value;
        } else if (type === "cmt-celana") {
          newData[i].statusCmtCelana = value;
        }
      }
    }

    setDataGambar(newData);
  }

  const reassignStatus = (id) => {
    let newData = dataGambar;

    for (let i = 0; i < newData.length; i++) {
      if (newData[i].id == id) {
        form.setFieldValue('statusTukangPotong', newData[i].statusTukangPotong);
        form.setFieldValue('statusSablon', newData[i].statusSablon);
        form.setFieldValue('statusBordir', newData[i].statusBordir);
        form.setFieldValue('statusCmt', newData[i].statusCmt);
        form.setFieldValue('statusCmtCelana', newData[i].statusCmtCelana);

        if (newData[i].statusTukangPotong === "Rusak") {
          setDisableTukangPotong(false);
        } else {
          setDisableTukangPotong(true);
        }

        if (newData[i].statusSablon === "Rusak") {
          setDisableSablon(false);
        } else {
          setDisableSablon(true);
        }

        if (newData[i].statusBordir === "Rusak") {
          setDisableBordir(false);
        } else {
          setDisableBordir(true);
        }

        if (newData[i].statusCmt === "Rusak") {
          setDisableCmt(false);
        } else {
          setDisableCmt(true);
        }

        if (newData[i].statusCmtCelana === "Rusak") {
          setDisableCmtCelana(false);
        } else {
          setDisableCmtCelana(true);
        }
      }
    }
  }

  useEffect(() => {
    fetchPoGambarDropdown();
  }, [
    selectedData.id,
  ]);

  useEffect(() => {
    fetchData();
  }, [
    filterOptionSelected,
    filterSearchValue,
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
  ]);

  useEffect(() => {
    fetchDataStok();
  }, [
    selectedData.id,
    tableParamsStok.pagination?.current,
    tableParamsStok.pagination?.pageSize,
    tableParamsStok?.sortOrder,
    tableParamsStok?.sortField,
  ]);

  useEffect(() => {
    fetchPoGambar();
  }, [
    selectedData.id,
    tableParamsGambar.pagination?.current,
    tableParamsGambar.pagination?.pageSize,
    tableParamsGambar?.sortOrder,
    tableParamsGambar?.sortField,
  ]);

  useEffect(() => {
    fetchToko();
    fetchMerek();
    fetchJenisPakaian();
    fetchAksesoris();
    fetchVariasi();
  }, []);

  useEffect(() => {
    fetchDropdown("jenis-bahan");
    fetchDropdown("ketebalan-bahan");
    fetchDropdown("gramasi");
    fetchDropdown("warna-bahan");
    fetchDropdown("setting-bahan");
  }, [
    filterDropdownMasterBahan
  ]);

  useEffect(() => {
    calculateDataPo();
  }, [
    dataGambar,
    gambarSelectedId,
  ]);

  return (
    <Context.Provider value={true}>
      {contextHolder}
      
      <Flex gap="middle" vertical={false}>
        <Space>
          <Select
            size="large"
            value={filterOptionSelected}
            onChange={handleChangeOption}
            style={{ width: 250 }}
            options={filterOptions}
          />
          <Input 
            size="large"
            style={{ width: 300 }}
            onChange={handleChangeValue}
            value={filterSearchValue} />
        </Space>
        <Space align='end' style={{ justifyContent: 'end', width: '100%' }}>
          <Button 
            disabled={selectedRowKeys.length === 0}
            size="large" 
            type="primary"
            icon={<PrinterOutlined />}
            iconPosition='start'
            onClick={print}
          >
            Print
          </Button>
        </Space>
      </Flex>
      <Flex gap="middle" vertical style={{marginTop: '24px'}}>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          columns={tableColumns}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={tableParams.pagination}
          loading={{
            spinning: loading,
            indicator: <Spinner className="spinner"/>
          }}
          onChange={handleTableChange}
        />
      </Flex>

      <Modal
        title={mode === "View" ? "View Purchase Order (PO)" : "Edit Purchase Order (PO)"}
        open={isOpenModal}
        width={900}
        closable={false}
        footer={[]}
      >
        <Form
          form={form}
          name="po-form"
          className="po-form"
          layout="vertical"
          autoComplete="off"
          autoFocus={true}
          onFinish={onFinish}
        >
          <Space>
            <Form.Item
              layout="vertical"
              label="No. Purchase Order (PO)"
              name="noPo"
              style={{width: '494px'}}
            >
              <Input size="large" disabled placeholder='Generated automated'/>
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Nama Toko"
              name="namaToko"
              style={{width: '350px'}}
            >
              <Select 
                showSearch
                disabled
                placeholder="Pilih nama toko"
                options={tokoOpt} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                size="large" />
            </Form.Item>
          </Space>

          <Space>
            <Form.Item
              layout="vertical"
              label="Merek"
              name="merek"
              style={{width: '494px'}}
            >
              <Select 
                disabled
                showSearch
                placeholder="Pilih merek"
                options={merekOpt} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                size="large" />
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Tanggal Potong"
              name="tanggalPotongAnt"
              style={{width: '350px'}}
            >
              <DatePicker 
                disabled
                size='large'
                style={{width: '100%'}}
              />
            </Form.Item>
          </Space>

          <Space>
            <Form.Item
              layout="vertical"
              label="Jenis Pakaian"
              name="jenisPakaian"
              style={{width: '350px'}}
            >
              <Select 
                showSearch
                disabled
                placeholder="Pilih jenis pakaian"
                options={jenisPakaianOpt} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                onChange={(value) => {
                  form.setFieldsValue({
                    namaPakaian: "",
                  });
                  fetchNamaPakaian(value);

                  if (value !== selectedData.jenisPakaian) {
                    setSelectedData({
                      ...selectedData,
                      init: true,
                    });
                  } else {
                    setSelectedData({
                      ...selectedData,
                      init: false,
                    });
                  }
                }}
                size="large" />
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Nama Pakaian"
              name="namaPakaian"
              style={{width: '494px'}}
            >
              <Select 
                showSearch
                disabled={mode === "View" || namaPakaianOpt.length === 0 || selectedData.statusProduksi !== "Draft"}
                placeholder="Pilih nama pakaian"
                options={namaPakaianOpt} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                onChange={(value) => {
                  if (value !== selectedData.namaPakaian) {
                    setSelectedData({
                      ...selectedData,
                      init: true,
                    });
                  } else {
                    setSelectedData({
                      ...selectedData,
                      init: false,
                    });
                  }
                }}
                optionLabelProp="label"
                size="large" />
            </Form.Item>
          </Space>
          
          {
            selectedData.init !== true &&
            <>
            <Table
              columns={tableColumnsStok}
              rowKey={(record) => record.id}
              dataSource={dataStok}
              pagination={tableParamsStok.pagination}
              loading={{
                spinning: loadingStok,
                indicator: <Spinner className="spinner"/>
              }}
              onChange={handleTableChangeStok}
            />

            <Form.Item
              layout="vertical"
              label="Total Berat Kain (Kg)"
              name="totalBeratKain"
              style={{width: '100%', marginTop: '12px'}}
            >
              <InputNumber size="large" disabled
                placeholder='Total berat kain (kg)'
                min={1} style={{width: '100%'}} />
            </Form.Item>

            <Table
              columns={tableColumnsGambar}
              rowKey={(record) => record.id}
              dataSource={dataGambar}
              pagination={tableParamsGambar.pagination}
              loading={{
                spinning: loadingGambar,
                indicator: <Spinner className="spinner"/>
              }}
              onChange={handleTableChangeGambar}
            />

            <Form.Item
              layout="vertical"
              label="Total Keseluruhan Lusin"
              name="totalLusin"
              style={{width: '100%', marginTop: '12px'}}
            >
              <InputNumber size="large" disabled
                placeholder='Total Keseluruhan Lusin'
                min={1} style={{width: '100%'}} />
            </Form.Item>

            <Divider />

            <h3 style={{textAlign: 'center'}}>Paraf & Keterangan</h3>


            <Form.Item
              layout="vertical"
              label="Pilih Gambar"
              name="gambarSelectedOpt"
              style={{width: '100%'}}
            >
              <Select 
                placeholder="Pilih gambar"
                options={poGambarOpt} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                size="large" 
                onChange={(value) => {
                  setGambarSelectedId(value);
                  reassignStatus(value);
                }}/>
            </Form.Item>

            <label style={{fontWeight:'bold'}}>Tukang Potong</label>
            <Space style={{marginTop: '8px'}}>
              <Form.Item
                layout="vertical"
                label=""
                name="namaTukangPotong"
                style={{width: '494px'}}
              >
                <Input size="large" placeholder='Nama tukang potong'
                  disabled />
              </Form.Item>
              <Form.Item
                layout="vertical"
                label=""
                name="tglDiserahkanTukangPotongAnt"
                style={{width: '350px'}}
              >
                <DatePicker 
                  disabled
                  size='large'
                  style={{width: '100%'}}
                  placeholder='Pilih tanggal diserahkan'
                />
              </Form.Item>
            </Space>

            {
              gambarSelectedId !== "" &&
              <Space style={{width: '100%'}} className='make-first-top'>
                <Space>
                  <Form.Item
                    layout="vertical"
                    label=""
                    name="statusTukangPotong"
                    style={{width: '250px'}}
                  >
                    <Select 
                      placeholder="Status"
                      options={poParafStatus} 
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      optionLabelProp="label"
                      size="large" 
                      disabled={dataGambar.filter(item => item.id == gambarSelectedId)[0].isStatusTukangPotongDone || !selectedData.isDiserahkanTukangPotong}
                      onChange={(value) => {
                        changeValueStatus('tukang-potong', value);
                        if (value === "Selesai") {
                          setDisableTukangPotong(true)
                        } else {
                          setDisableTukangPotong(false)
                        }
                      }}/>
                  </Form.Item>
                </Space>
                <Space style={{display: 'block'}}>
                {
                  dataGambar.filter(item => item.id == gambarSelectedId).length > 0 &&
                  dataGambar.filter(item => item.id == gambarSelectedId)[0].sizes?.map(obj => (
                    <Space key={obj.id}>
                      <Form.Item
                        layout="vertical"
                        label=""
                        name={"sizekerusakanTukangPotong" +obj.id}
                        initialValue={obj.ukuran}
                        style={{width: '235px'}}
                      >
                        <Input size="large" placeholder='Ukuran' disabled />
                      </Form.Item>
                      <Form.Item
                        layout="vertical"
                        label=""
                        name={"kerusakanTukangPotong"+obj.id}
                        initialValue={obj.kerusakanTukangPotong}
                        style={{width: '350px'}}
                      > 
                        <InputNumber 
                          style={{width: '100%'}} 
                          size="large" 
                          placeholder='Piece' 
                          disabled={dataGambar.filter(item => item.id == gambarSelectedId)[0].isStatusTukangPotongDone || !selectedData.isDiserahkanTukangPotong || disableTukangPotong}
                          onChange={(value) => {
                            changeValue(obj.id, "tukang-potong", value);
                          }}
                        />
                      </Form.Item>
                    </Space>
                  ))
                }
                </Space>
              </Space>
            }

            <label style={{fontWeight:'bold', width: '100%'}}>Sablon</label>
            <Space style={{marginTop: '8px'}}>
              <Form.Item
                layout="vertical"
                label=""
                name="namaTukangSablon"
                style={{width: '494px'}}
              >
                <Input size="large" placeholder='Nama tukang sablon'
                  disabled />
              </Form.Item>
              <Form.Item
                layout="vertical"
                label=""
                name="tglDiserahkanTukangSablonAnt"
                style={{width: '350px'}}
              >
                <DatePicker 
                  disabled
                  size='large'
                  style={{width: '100%'}}
                  placeholder='Pilih tanggal diserahkan'
                />
              </Form.Item>
            </Space>

            {
              gambarSelectedId !== "" &&
              <Space style={{width: '100%'}} className='make-first-top'>
                <Space>
                  <Form.Item
                    layout="vertical"
                    label=""
                    name="statusSablon"
                    style={{width: '250px'}}
                  >
                    <Select 
                      placeholder="Status"
                      options={poParafStatus} 
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      optionLabelProp="label"
                      size="large" 
                      disabled={dataGambar.filter(item => item.id == gambarSelectedId)[0].isStatusSablonDone || !selectedData.isDiserahkanTukangSablon}
                      onChange={(value) => {
                        changeValueStatus('sablon', value);
                        if (value === "Selesai") {
                          setDisableSablon(true)
                        } else {
                          setDisableSablon(false)
                        }
                      }}/>
                  </Form.Item>
                </Space>
                <Space style={{display: 'block'}}>
                {
                  dataGambar.filter(item => item.id == gambarSelectedId).length > 0 &&
                  dataGambar.filter(item => item.id == gambarSelectedId)[0].sizes?.map(obj => (
                    <Space key={obj.id}>
                      <Form.Item
                        layout="vertical"
                        label=""
                        name={"sizekerusakanSablon" +obj.id}
                        initialValue={obj.ukuran}
                        style={{width: '235px'}}
                      >
                        <Input size="large" placeholder='Ukuran' disabled />
                      </Form.Item>
                      <Form.Item
                        layout="vertical"
                        label=""
                        name={"kerusakanSablon"+obj.id}
                        initialValue={obj.kerusakanSablon}
                        style={{width: '350px'}}
                      >
                        <InputNumber 
                          style={{width: '100%'}} 
                          size="large" 
                          placeholder='Piece' 
                          disabled={dataGambar.filter(item => item.id == gambarSelectedId)[0].isStatusSablonDone || !selectedData.isDiserahkanTukangSablon || disableSablon}
                          onChange={(value) => {
                            changeValue(obj.id, "sablon",value);
                          }}
                        />
                      </Form.Item>
                    </Space>
                  ))
                }
                </Space>
              </Space>
            }

            <label style={{fontWeight:'bold', width: '100%'}}>Bordir</label>
            <Space style={{marginTop: '8px'}}>
              <Form.Item
                layout="vertical"
                label=""
                name="namaTukangBordir"
                style={{width: '494px'}}
              >
                <Input size="large" placeholder='Nama tukang bordir'
                  disabled />
              </Form.Item>
              <Form.Item
                layout="vertical"
                label=""
                name="tglDiserahkanTukangBordirAnt"
                style={{width: '350px'}}
              >
                <DatePicker 
                  disabled
                  size='large'
                  style={{width: '100%'}}
                  placeholder='Pilih tanggal diserahkan'
                />
              </Form.Item>
            </Space>

            {
              gambarSelectedId !== "" &&
              <Space style={{width: '100%'}} className='make-first-top'>
                <Space>
                  <Form.Item
                    layout="vertical"
                    label=""
                    name="statusBordir"
                    style={{width: '250px'}}
                  >
                    <Select 
                      placeholder="Status"
                      options={poParafStatus} 
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      optionLabelProp="label"
                      size="large" 
                      disabled={dataGambar.filter(item => item.id == gambarSelectedId)[0].isStatusBordirDone || !selectedData.isDiserahkanTukangBordir}
                      onChange={(value) => {
                        changeValueStatus('bordir', value);
                        if (value === "Selesai") {
                          setDisableBordir(true)
                        } else {
                          setDisableBordir(false)
                        }
                      }}/>
                  </Form.Item>
                </Space>
                <Space style={{display: 'block'}}>
                {
                  dataGambar.filter(item => item.id == gambarSelectedId).length > 0 &&
                  dataGambar.filter(item => item.id == gambarSelectedId)[0].sizes?.map(obj => (
                    <Space key={obj.id}>
                      <Form.Item
                        layout="vertical"
                        label=""
                        name={"sizekerusakanBordir" +obj.id}
                        initialValue={obj.ukuran}
                        style={{width: '235px'}}
                      >
                        <Input size="large" placeholder='Ukuran' disabled />
                      </Form.Item>
                      <Form.Item
                        layout="vertical"
                        label=""
                        name={"kerusakanBordir"+obj.id}
                        initialValue={obj.kerusakanBordir}
                        style={{width: '350px'}}
                      >
                        <InputNumber 
                          style={{width: '100%'}} 
                          size="large" 
                          placeholder='Piece' 
                          disabled={dataGambar.filter(item => item.id == gambarSelectedId)[0].isStatusBordirDone || !selectedData.isDiserahkanTukangBordir || disableBordir}
                          onChange={(value) => {
                            changeValue(obj.id, "bordir",value);
                          }}
                        />
                      </Form.Item>
                    </Space>
                  ))
                }
                </Space>
              </Space>
            }

            <label style={{fontWeight:'bold'}}>Cut Make Trim (CMT)</label>
            <Space style={{marginTop: '8px'}}>
              <Form.Item
                layout="vertical"
                label=""
                name="namaTukangCmt"
                style={{width: '494px'}}
              >
                <Input size="large" placeholder='Nama tukang cmt'
                  disabled />
              </Form.Item>
              <Form.Item
                layout="vertical"
                label=""
                name="tglDiserahkanTukangCmtAnt"
                style={{width: '350px'}}
              >
                <DatePicker 
                  disabled
                  size='large'
                  style={{width: '100%'}}
                  placeholder='Pilih tanggal diserahkan'
                />
              </Form.Item>
            </Space>

            {
              gambarSelectedId !== "" &&
              <Space style={{width: '100%'}} className='make-first-top'>
                <Space>
                  <Form.Item
                    layout="vertical"
                    label=""
                    name="statusCmt"
                    style={{width: '250px'}}
                  >
                    <Select 
                      placeholder="Status"
                      options={poParafStatus} 
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      optionLabelProp="label"
                      size="large" 
                      disabled={dataGambar.filter(item => item.id == gambarSelectedId)[0].isStatusCmtDone || !selectedData.isDiserahkanTukangCmt}
                      onChange={(value) => {
                        changeValueStatus('cmt', value);
                        if (value === "Selesai") {
                          setDisableCmt(true)
                        } else {
                          setDisableCmt(false)
                        }
                      }}/>
                  </Form.Item>
                </Space>
                <Space style={{display: 'block'}}>
                {
                  dataGambar.filter(item => item.id == gambarSelectedId).length > 0 &&
                  dataGambar.filter(item => item.id == gambarSelectedId)[0].sizes?.map(obj => (
                    <Space key={obj.id}>
                      <Form.Item
                        layout="vertical"
                        label=""
                        name={"sizekerusakanCmt" +obj.id}
                        initialValue={obj.ukuran}
                        style={{width: '235px'}}
                      >
                        <Input size="large" placeholder='Ukuran' disabled />
                      </Form.Item>
                      <Form.Item
                        layout="vertical"
                        label=""
                        name={"kerusakanCmt"+obj.id}
                        initialValue={obj.kerusakanCmt}
                        style={{width: '350px'}}
                      >
                        <InputNumber 
                          style={{width: '100%'}} 
                          size="large" 
                          placeholder='Piece' 
                          disabled={dataGambar.filter(item => item.id == gambarSelectedId)[0].isStatusCmtDone || !selectedData.isDiserahkanTukangCmt || disableCmt}
                          onChange={(value) => {
                            changeValue(obj.id, "cmt", value);
                          }}
                        />
                      </Form.Item>
                    </Space>
                  ))
                }
                </Space>
              </Space>
            }

            <label style={{fontWeight:'bold', width: '100%'}}>Cut Make Trim (CMT) Celana</label>
            <Space style={{marginTop: '8px'}}>
              <Form.Item
                layout="vertical"
                label=""
                name="namaTukangCmtCelana"
                style={{width: '494px'}}
              >
                <Input size="large" placeholder='Nama tukang cmt celana'
                  disabled />
              </Form.Item>
              <Form.Item
                layout="vertical"
                label=""
                name="tglDiserahkanTukangCmtCelanaAnt"
                style={{width: '350px'}}
              >
                <DatePicker 
                  disabled
                  size='large'
                  style={{width: '100%'}}
                  placeholder='Pilih tanggal diserahkan'
                />
              </Form.Item>
            </Space>

            {
              gambarSelectedId !== "" &&
              <Space style={{width: '100%'}} className='make-first-top'>
                <Space>
                  <Form.Item
                    layout="vertical"
                    label=""
                    name="statusCmtCelana"
                    style={{width: '250px'}}
                  >
                    <Select 
                      placeholder="Status"
                      options={poParafStatus} 
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      optionLabelProp="label"
                      size="large" 
                      disabled={dataGambar.filter(item => item.id == gambarSelectedId)[0].isStatusCmtCelanaDone || !selectedData.isDiserahkanTukangCmtCelana}
                      onChange={(value) => {
                        changeValueStatus('cmt-celana', value);
                        if (value === "Selesai") {
                          setDisableCmtCelana(true)
                        } else {
                          setDisableCmtCelana(false)
                        }
                      }}/>
                  </Form.Item>
                </Space>
                <Space style={{display: 'block'}}>
                {
                  dataGambar.filter(item => item.id == gambarSelectedId).length > 0 &&
                  dataGambar.filter(item => item.id == gambarSelectedId)[0].sizes?.map(obj => (
                    <Space key={obj.id}>
                      <Form.Item
                        layout="vertical"
                        label=""
                        name={"sizekerusakanCmtCelana" +obj.id}
                        initialValue={obj.ukuran}
                        style={{width: '235px'}}
                      >
                        <Input size="large" placeholder='Ukuran' disabled />
                      </Form.Item>
                      <Form.Item
                        layout="vertical"
                        label=""
                        name={"kerusakanCmtCelana"+obj.id}
                        initialValue={obj.kerusakanCmtCelana}
                        style={{width: '350px'}}
                      >
                        <InputNumber 
                          style={{width: '100%'}} 
                          size="large" 
                          placeholder='Piece' 
                          disabled={dataGambar.filter(item => item.id == gambarSelectedId)[0].isStatusCmtCelanaDone || !selectedData.isDiserahkanTukangCmtCelana || disableCmtCelana}
                          onChange={(value) => {
                            changeValue(obj.id, "cmt-celana", value);
                          }}
                        />
                      </Form.Item>
                    </Space>
                  ))
                }
                </Space>
              </Space>
            }

            <label style={{fontWeight:'bold'}}>Diketahui Oleh</label>
            <Space style={{marginTop: '8px'}}>
              <Form.Item
                layout="vertical"
                label=""
                name="namaPic"
                style={{width: '494px'}}
              >
                <Input size="large" placeholder='Nama penanggung jawab'
                  disabled />
              </Form.Item>
              <Form.Item
                layout="vertical"
                label=""
                name="tglDiserahkanPicAnt"
                style={{width: '350px'}}
              >
                <DatePicker 
                  disabled
                  size='large'
                  style={{width: '100%'}}
                  placeholder='Pilih tanggal diserahkan'
                />
              </Form.Item>
            </Space>

            <Space style={{marginTop: '8px'}}>
              <Form.Item
                layout="vertical"
                label="Total Lusin"
                name="totalLusinGambar"
                style={{width: '494px'}}
              >
                <Input size="large" placeholder='Total Lusin'
                  disabled />
              </Form.Item>
              <Form.Item
                layout="vertical"
                label="Total Piece"
                name="totalPieceGambar"
                style={{width: '350px'}}
              >
                <Input size="large" placeholder='Total piece'
                  disabled />
              </Form.Item>
            </Space>
            </>
          }

          <Divider />

          <h3 style={{textAlign: 'center'}}>Total Quantity Setelah Dihitung</h3>
          <Space style={{marginTop: '8px'}}>
            <Form.Item
              layout="vertical"
              label="Total Keseluruhan Lusin"
              name="totalLusinGambarKeseluruhan"
              style={{width: '494px'}}
            >
              <Input size="large" placeholder='Total Lusin'
                disabled />
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Total Keseluruhan Piece"
              name="totalPieceGambarKeseluruhan"
              style={{width: '350px'}}
            >
              <Input size="large" placeholder='Total piece'
                disabled />
            </Form.Item>
          </Space>

          <Space
            align='end'
            style={{ flexBasis: '100%', flexDirection: 'column-reverse', width: '100%', marginTop: '28px', }}
          >
            <Form.Item>
              <Button htmlType="button" size="large" style={{marginRight: '8px'}} onClick={onCancel}> 
                {selectedData.statusProduksi !== "Submitted" && selectedData.statusAdministrasi !== "Done" ? "Cancel" : "Close"}
              </Button>
              {
                selectedData.statusProduksi !== "Submitted" && selectedData.statusAdministrasi !== "Done" && selectedData.init !== true &&
                <Button type="primary" htmlType="submit" size="large">
                  Submit
                </Button>
              }
            </Form.Item>
          </Space>
        </Form>
      </Modal>

      <Modal
        title="View Stok"
        open={isOpenModalStok}
        width={700}
        closable={false}
        footer={[]}
      >
        <Form
          form={formStok}
          name="stok-form"
          className="stok-form"
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item
            layout="vertical"
            label="Jenis Bahan"
            name="jenisBahan"
            style={{width: '652px'}}
          >
            <Select 
              showSearch
              allowClear
              disabled
              placeholder="Select jenis bahan"
              options={dropdownJenisBahan} 
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              optionLabelProp="label"
              size="large" 
              onChange={(value) => {
                onChangeDropdown("jenis-bahan", value);
              }}/>
          </Form.Item>

          <Space>
            <Form.Item
              layout="vertical"
              label="Ketebalan Bahan"
              name="ketebalanBahan"
              style={{width: '212px'}}
            >
              <Select 
                showSearch
                allowClear
                disabled
                placeholder="Select ketebalan bahan"
                options={dropdownKetebalanBahan} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                size="large" 
                onChange={(value) => {
                  onChangeDropdown("ketebalan-bahan", value);
                }}
              />
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Gramasi"
              name="gramasi"
              style={{width: '211px'}}
            >
              <Select 
                showSearch
                allowClear
                disabled
                placeholder="Select gramasi"
                options={dropdownGramasiBahan} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                size="large" 
                onChange={(value) => {
                  onChangeDropdown("gramasi", value);
                }}
              />
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Warna Bahan"
              name="warnaBahan"
              style={{width: '212px'}}
            >
              <Select 
                showSearch
                allowClear
                disabled
                placeholder="Select warna bahan"
                options={dropdownWarnaBahan} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                size="large" 
                onChange={(value) => {
                  onChangeDropdown("warna-bahan", value);
                }}
              />
            </Form.Item>
          </Space>
          <Space>
            <Form.Item
              layout="vertical"
              label="Setting Bahan"
              name="settingBahan"
              style={{width: '322px'}}
            >
              <Select 
                showSearch
                allowClear
                disabled
                placeholder="Select setting bahan"
                options={dropdownSettingBahan} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                size="large" 
                onChange={(value) => {
                  onChangeDropdown("setting-bahan", value);
                }}/>
            </Form.Item>

            <Form.Item
              layout="vertical"
              label="Berat Kain (Kg)"
              name="beratKain"
              style={{width: '322px'}}
            >
              <InputNumber size="large" 
                disabled
                placeholder='Input berat kain (kg)'
                min={1} style={{width: '100%'}} />
            </Form.Item>
          </Space>

          
          <Form.Item
            layout="vertical"
            label="Keterangan"
            name="keterangan"
          >
            <Input.TextArea 
              disabled
              autoSize={{ minRows: 3, maxRows: 5 }}/>
          </Form.Item>

          <Space
            align='end'
            style={{ flexBasis: '100%', flexDirection: 'column-reverse', width: '100%', marginTop: '28px', }}
          >
            <Form.Item>
              <Button htmlType="button" size="large" style={{marginRight: '8px'}} onClick={onCancelStok}> 
                Close
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </Modal>

      <Modal
        title="View Detail"
        open={isOpenModalGambar}
        width={700}
        closable={false}
        footer={[]}
      >
        <Form
          form={formGambar}
          name="gambar-form"
          className="gambar-form"
          layout="vertical"
          autoComplete="off"
        >

          <Form.Item
            layout="vertical"
            label="Kode Gambar"
            name="kodeGambar"
          >
            <Input size="large" placeholder='Kode gambar' disabled />
          </Form.Item>
          <Space>
            <Space style={{marginBottom: '16px', border:'1px solid #b8ded8', borderRadius: '8px'}}>
              <Image
                alt={selectedDataGambar.namaGambar === null ? "no-image": selectedDataGambar.namaGambar}
                width={284}
                height={284}
                style={{objectFit: 'contain', padding: '12px'}}
                src={selectedDataGambar.urlGambar === null ? noImage : BASE_URL + selectedDataGambar.urlGambar}
              />
            </Space>
            <Space style={{display: 'block'}}>
            {
              selectedDataGambar.sizes.map(obj => (
                <Space key={obj.id}>
                  <Form.Item
                    layout="vertical"
                    label=""
                    name={"size" +obj.id}
                    initialValue={obj.ukuran}
                    style={{width: '100px'}}
                  >
                    <Input size="large" placeholder='Ukuran' disabled />
                  </Form.Item>
                  <Form.Item
                    layout="vertical"
                    label=""
                    name={"jumlah"+obj.id}
                    initialValue={obj.jumlah}
                    style={{width: '250px'}}
                  >
                    <InputNumber 
                      style={{width: '100%'}} 
                      size="large" 
                      placeholder='Total jumlah Lusin' 
                      disabled
                    />
                  </Form.Item>
                </Space>
              ))
            }
            </Space>
          </Space>

          <Form.Item
            layout="vertical"
            label="Total Jumlah Lusin"
            name="jumlahLusin"
          >
            <InputNumber style={{width: '100%'}} size="large" placeholder='Total jumlah Lusin' disabled />
          </Form.Item>

          <Form.Item
            layout="vertical"
            label="Variasi"
            name="variasi"
          >
            <Select 
              showSearch
              disabled
              mode="multiple"
              placeholder="Tambah Variasi"
              options={variasiOpt} 
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              optionLabelProp="label"
              size="large" />
          </Form.Item>

          <Form.Item
            layout="vertical"
            label="Aksesoris"
            name="aksesoris"
          >
            <Select 
              showSearch
              disabled
              mode="multiple"
              placeholder="Tambah Aksesoris"
              options={aksesorisOpt} 
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              optionLabelProp="label"
              size="large" />
          </Form.Item>

          <Space
            align='end'
            style={{ flexBasis: '100%', flexDirection: 'column-reverse', width: '100%', marginTop: '28px', }}
          >
            <Form.Item>
              <Button htmlType="button" size="large" style={{marginRight: '8px'}} 
              onClick={() => {
                setIsOpenModalGambar(false);
                resetFormGambar();
                fetchTotalLusin();
              }}> 
                Close
              </Button>
            </Form.Item>
          </Space>    
        </Form>    
      </Modal>
      <Image.PreviewGroup
        preview={{
          visible: previewImages.visible,
          onVisibleChange: (value) => {
            setPreviewImages({
              ...previewImages,
              visible: value,
            });
          },
        }}
        items={previewImages.items}
      />

      <Modal
        title="Preview QR Code"
        open={isOpenModalQr}
        width={500}
        closable={false}
        footer={[]}
      >
        <Space id="qr" direction="vertical"  style={{ justifyContent: 'end', width: '100%' }}>
          <Flex gap="middle" vertical>
            <label style={{fontSize: '16px', fontWeight:'bold', textAlign: 'center'}}>Purchase Order (PO) - Administrasi</label>
            <label style={{fontSize: '24px', fontWeight:'bold', textAlign: 'center'}}>{selectedData.merek}</label>
            <label style={{fontSize: '20px', fontWeight:'bold', textAlign: 'center'}}>{selectedData.jenisPakaian.toUpperCase()} - {selectedData.namaPakaian}</label>
            
            <QRCode
              type='svg'
              style={{
                width: '352px',
                height: '352px',
                margin: 'auto',
              }}
              errorLevel="H"
              value={qrData}
            />

            <label style={{marginBottom:"16px", fontWeight:'bold', textAlign:'center'}}>Dicetak Oleh : CV. Ian Putra Reason</label>
          </Flex>
        </Space>

        <Space align='end' style={{ justifyContent: 'end', width: '100%' }}>
          <Button htmlType="button" size="large" style={{marginRight: '8px'}} onClick={onClose}> 
            Close
          </Button>
          <Button type="primary" size="large" onClick={onPrint}>
            Print
          </Button>
        </Space>
      </Modal>
    </Context.Provider>
  )
}

const POAdmin = () => {
  return (
    <MasterLayout
      pageName="Purchase Order (PO) - Administrasi"
      activeMenu="po-administrasi" 
      activeParent="po"
      content={Content()}
    />
  )
}

export default POAdmin;