import { CallRestApi, CallRestApiSecure } from '../../libs/Api';

export const login = ({ username, password, isRememberMe }) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const payload = {
      username: username,
      password: password,
      isRememberMe: isRememberMe
    };

    const response = await CallRestApi('post', BASE_URL, 'v1/auth/login', payload, {}, {});
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const getMenu = () => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('get', BASE_URL, 'v1/auth/menu', {}, {}, {});
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};

export const getRoles = () => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  try {
    const response = await CallRestApiSecure('get', BASE_URL, 'v1/auth/roles', {}, {}, {});
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};