import axios from 'axios';
import { GetToken, RemoveToken } from './Auth';

const LogoutAction = () => {
  RemoveToken();
  window.location.href = '/login';
};

export const CallRestApi = async (method, url, route, data, params, responseType) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  const response = await axios({
    method,
    url: `${url}/${route}`,
    data,
    headers,
    params,
    responseType,
  });

  return response.data;
};

export const CallRestApiSecure = async (method, url, route, data, params, responseType, contentType) => {
  const headers = {
    'Content-Type': contentType || 'application/json',
    Authorization: `Bearer ${(GetToken())}`,
  };

  let response;

  try {
    response = await axios({
      method,
      url: `${url}/${route}`,
      data,
      headers,
      params,
      responseType,
    });
    
    window.localStorage.removeItem('reason-season-redirect-to');
  } catch (error) {
    if (error.response.status === 401) {
      LogoutAction();
    } else {
      throw (error);
    }
  }

  return response.data;
};
