import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: { userId: '', username: '', fullName: '' },
  userId: '',
  username: '',
  fullName: '',
  roles: [],
  moduleAccess: [],
  loggedIn: false,
  expandedSidebar: true,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setFullName: (state, action) => {
      state.fullName = action.payload;
    },
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
    setModuleAccess: (state, action) => {
      state.moduleAccess = action.payload;
    },
    setLoggedIn: (state, action) => {
      state.loggedIn = action.payload;
    },
    setExpandedSidebar: (state, action) => {
      state.expandedSidebar = action.payload;
    },
  },
});

export const { setUserId, setUsername, setFullName, setRoles, setModuleAccess, setLoggedIn, setExpandedSidebar } = appSlice.actions;

export default appSlice.reducer;
