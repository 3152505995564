import './Dashboard.scss';
import MasterLayout from '../../components/MasterLayout';

const content = () => {
  return (
    <div>Dashboard</div>
  )
}

const Dashboard = () => {
  return (
    <MasterLayout
      pageName="Dashboard"
      activeMenu="dashboard" 
      content={content()}
    />
  )
}

export default Dashboard;