import './HargaJual.scss';
import MasterLayout from '../../components/MasterLayout';
import Spinner from '../../components/Spinner';
import React, { useEffect, useState } from 'react';
import { 
  Flex, 
  Select, 
  Space, 
  Input, 
  Button,
  Table,
  Modal,
  Form,
  notification,
} from 'antd';
import {
  EyeOutlined,
} from '@ant-design/icons';
import * as hargaJualApi from '../../stores/business/HargaJualApi';
import * as pakaianApi from '../../stores/business/PakaianApi';
import * as merekApi from '../../stores/business/MerekApi';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const Context = React.createContext({
  name: 'Default',
});

const Content = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (message, description, type) => {
    if (type === "error") {
      api.error({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    } else if (type === "success") {
      api.success({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    }
  };

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [disabledButtonAksesoris, setDisabledButtonAksesoris] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingSize, setLoadingSize] = useState(false);
  const [data, setData] = useState();
  const [dataSize, setDataSize] = useState();
  const [sizeGroupOpt, setSizeGroupOpt] = useState([]);
  const [filterOptionSelected, setFilterOptionSelected] = useState('merek');
  const [filterSearchValue, setFilterSearchValue] = useState('');
  const [jenisPakaianOpt, setJenisPakaianOpt] = useState([]);
  const [namaPakaianOpt, setNamaPakaianOpt] = useState([]);
  const [merekOpt, setMerekOpt] = useState([]);
  const [selectedData, setSelectedData] = useState({
    id: null,
    hargaModalSizeId: null,
    jenisPakaian: null,
    namaPakaian: null,
    jenisBahan: null,
    sizeGroup: null,
    jenisBahanList: [{
      jenisBahan: null,
    }]
  });
  
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: "id",
    sortOrder: "descend",
  });
  
  const [tableParamsSize, setTableParamsSize] = useState({
    pagination: {
      current: 1,
      pageSize: 100,
    },
    sortField: "id",
    sortOrder: "ascend",
  });

  const tableColumns = [
    {
      title: 'Nama Merek',
      dataIndex: 'merek',
      key: 'merek',
      sorter: true,
    },
    {
      title: 'Jenis Pakaian',
      dataIndex: 'jenisPakaian',
      key: 'jenisPakaian',
      sorter: true,
    },
    {
      title: 'Nama Pakaian',
      dataIndex: 'namaPakaian',
      key: 'namaPakaian',
      sorter: true,
    },
    {
      title: 'Jenis Bahan',
      dataIndex: 'jenisBahan',
      key: 'jenisBahan',
      sorter: true,
      render: (_, record) => (
        record.jenisBahan === null || record.jenisBahan === "" ? "-" : record.jenisBahan
      ),
    },
    {
      title: 'Ukuran',
      dataIndex: 'sizeGroup',
      key: 'sizeGroup',
      sorter: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button shape="circle" icon={<EyeOutlined />} onClick={() => {onEdit(record)}}/>
        </Space>
      ),
    },
  ];

  const tableColumnsSize = [
    {
      title: 'Ukuran',
      dataIndex: 'ukuran',
      key: 'ukuran',
    },
    {
      title: 'Harga Jual Toko',
      dataIndex: 'hargaJualToko',
      key: 'hargaJualToko',
      render: (_, record) => (
        record.hargaJualToko === null || record.hargaJualToko === "" ? "" : 
        new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(record.hargaJualToko).trim().split(",")[0]
      ),
    },
  ];

  const tableColumnsBahan = [
    {
      title: 'Jenis Bahan',
      dataIndex: 'jenisBahan',
      key: 'jenisBahan',
    },
  ];

  const filterOptions = [
    {value: 'merek', label: 'Nama Merek'},
    {value: 'jenisPakaian', label: 'Jenis Pakaian'},
    {value: 'namaPakaian', label: 'Nama Pakaian'},
    {value: 'jenisBahan', label: 'Jenis Bahan'},
    {value: 'sizeGroup', label: 'Ukuran'},
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableParams({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });
    
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleTableChangeSize = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableParamsSize({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });
    
    if (pagination.pageSize !== tableParamsSize.pagination?.pageSize) {
      setDataSize([]);
    }
  };

  const handleChangeOption = (value) => {
    setFilterOptionSelected(value);
  };

  const handleChangeValue = (e) => {
    setFilterSearchValue(e.target.value);
  };

  const onEdit = (record) => {
    record = {
      ...record,
      hargaModalSizeId: null,
      hargaTukangPotong: null,
      hargaSablon: null,
      hargaBordir: null,
      hargaCmt: null,
      hargaCmtCelana: null,
      hargaJualToko: null,
      hargaLainnya: null,
    }
    setSelectedData(record);
    form.setFieldsValue(record);
    fetchNamaPakaian(record.jenisPakaian);
    fetchSizeGroup(record.jenisPakaian, record.namaPakaian);
    setIsOpenModal(true)
  }

  const resetData = () => {
    setDisabledButtonAksesoris(true);
    form.resetFields();
    form.setFieldsValue({
      id: null,
      hargaModalSizeId: null,
      jenisPakaian: null,
      namaPakaian: null,
      jenisBahan: null,
      sizeGroup: null,
      hargaTukangPotong: null,
      hargaSablon: null,
      hargaBordir: null,
      hargaCmt: null,
      hargaCmtCelana: null,
      hargaJualToko: null,
      hargaAksesoris: null,
    });
    setSelectedData({
      id: null,
      hargaModalSizeId: null,
      jenisPakaian: null,
      namaPakaian: null,
      jenisBahan: null,
      sizeGroup: null,
      hargaTukangPotong: null,
      hargaSablon: null,
      hargaBordir: null,
      hargaCmt: null,
      hargaCmtCelana: null,
      hargaJualToko: null,
      hargaAksesoris: null,
    });
  }

  const fetchMerek = async () => {
    const response = await dispatch(merekApi.getMerekList());
    if (response.status === 200) {
      setMerekOpt(response.data);
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchData = async () => {
    setLoading(true);

    var sortField = tableParams.sortField === undefined ? "id" : tableParams.sortField;
    var sortOrder = tableParams.sortOrder === "ascend" ? "asc" : "desc"

    const response = await dispatch(hargaJualApi.getPage({
      "page": tableParams.pagination.current,
      "size": tableParams.pagination.pageSize,
      "sort": [
        sortField + " " + sortOrder,
      ],
      "search": [
        {
          field: filterOptionSelected,
          value: filterSearchValue
        }
      ]
    }));
    if (response.status === 200) {
      setData(response.data.contents);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response.data.totalData,
        }
      });
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoading(false);
  };

  const fetchDataSize = async () => {
    if (selectedData.id === null) return;

    setLoadingSize(true);

    var sortField = tableParamsSize.sortField === undefined ? "id" : tableParamsSize.sortField;
    var sortOrder = tableParamsSize.sortOrder === "ascend" ? "asc" : "desc"

    const response = await dispatch(hargaJualApi.getPageSize(selectedData.id, {
      "page": 1,
      "size": 100,
      "sort": [
        sortField + " " + sortOrder,
      ],
      "search": []
    }));
    if (response.status === 200) {
      let item = [];
      for (let i = 0; i < response.data.contents.length; i++) {
        let temp = response.data.contents[i];
        temp.row = i;
        item.push(temp);
      }
      setDataSize(item);
      setTableParamsSize({
        ...tableParamsSize,
        pagination: {
          ...tableParamsSize.pagination,
          total: response.data.totalData,
        }
      });
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoadingSize(false);
  };

  const fetchJenisPakaian = async () => {
    const response = await dispatch(pakaianApi.getJenisPakaianList());
    if (response.status === 200) {
      setJenisPakaianOpt(response.data);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/harga-modal")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchNamaPakaian = async (jenis) => {
    const response = await dispatch(pakaianApi.getNamaPakaianList(jenis));
    if (response.status === 200) {
      setNamaPakaianOpt(response.data);
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const fetchSizeGroup = async (jenis, nama) => {
    const response = await dispatch(pakaianApi.getSizeGroupList(jenis, nama));
    if (response.status === 200) {
      setSizeGroupOpt(response.data);
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const onCancel = () => {
    resetData();

    setIsOpenModal(false);
  };

  useEffect(() => {
    fetchData();
  }, [
    filterOptionSelected,
    filterSearchValue,
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
  ]);

  useEffect(() => {
    fetchDataSize();
  }, [
    selectedData.id,
    tableParamsSize.pagination?.current,
    tableParamsSize.pagination?.pageSize,
    tableParamsSize?.sortOrder,
    tableParamsSize?.sortField,
  ]);

  useEffect(() => {
    fetchJenisPakaian();
    fetchMerek();
  }, []);

  return (
    <Context.Provider value={true}>
      {contextHolder}
      
      <Flex gap="middle" vertical={false}>
        <Space>
          <Select
            size="large"
            value={filterOptionSelected}
            onChange={handleChangeOption}
            style={{ width: 250 }}
            options={filterOptions}
          />
          <Input 
            size="large"
            style={{ width: 300 }}
            onChange={handleChangeValue}
            value={filterSearchValue} />
        </Space>
      </Flex>
      <Flex gap="middle" vertical style={{marginTop: '24px'}}>
        <Table
          columns={tableColumns}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={tableParams.pagination}
          loading={{
            spinning: loading,
            indicator: <Spinner className="spinner"/>
          }}
          onChange={handleTableChange}
        />
      </Flex>

      <Modal
        title="View Harga"
        open={isOpenModal}
        width={900}
        closable={false}
        footer={[]}
      >
        {
          selectedData.id !== null && 
          <Form
            form={form}
            initialValues={selectedData}
            name="harga-modal-form"
            className="harga-modal-form"
            layout="vertical"
            autoComplete="off"
            autoFocus={true}
          >
            <Space>
              <Form.Item
                layout="vertical"
                label="Nama Merek"
                name="merek"
                style={{width: '279px'}}
              >
                <Select 
                  showSearch
                  disabled
                  placeholder="Pilih merek"
                  options={merekOpt} 
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  optionLabelProp="label"
                  size="large" />
              </Form.Item>
              <Form.Item
                layout="vertical"
                label="Jenis Pakaian"
                name="jenisPakaian"
                style={{width: '279px'}}
              >
                <Select 
                  showSearch
                  disabled
                  placeholder="Pilih jenis pakaian"
                  options={jenisPakaianOpt} 
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  optionLabelProp="label"
                  size="large" />
              </Form.Item>
              <Form.Item
                layout="vertical"
                label="Nama Pakaian"
                name="namaPakaian"
                style={{width: '279px'}}
              >
                <Select 
                  showSearch
                  disabled
                  placeholder="Pilih nama pakaian"
                  options={namaPakaianOpt} 
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  optionLabelProp="label"
                  size="large" />
              </Form.Item>
            </Space>

            <Form.Item
              layout="vertical"
              label="Ukuran"
              name="sizeGroup"
              rules={[
                {
                  required: true,
                  message: 'Pilih ukuran',
                },
              ]}
            >
              <Select 
                showSearch
                disabled
                placeholder="Pilih ukuran"
                options={sizeGroupOpt} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                onChange={(value) => {
                  setSelectedData({
                    ...selectedData,
                    sizeGroup: value,
                  });
                }}
                size="large" />
            </Form.Item>
            
            <span>
              <label style={{fontSize:'20px', fontWeight:'bold', marginTop:'16px'}}>Kain</label>
            </span>

            <Flex gap="middle" vertical style={{marginTop: '16px', marginBottom:'16px'}}>
              <Table
                bordered
                columns={tableColumnsBahan}
                rowKey={(record) => record.id}
                dataSource={selectedData.jenisBahanList}
                pagination={false}
                loading={{
                  spinning: loadingSize,
                  indicator: <Spinner className="spinner"/>
                }}
              />
            </Flex>

            <label style={{fontSize:'20px', fontWeight:'bold'}}>Harga Toko</label>

            <Flex gap="middle" vertical style={{marginTop: '16px'}}>
              <Table
                bordered
                columns={tableColumnsSize}
                rowKey={(record) => record.id}
                dataSource={dataSize}
                pagination={false}
                loading={{
                  spinning: loadingSize,
                  indicator: <Spinner className="spinner"/>
                }}
                onChange={handleTableChangeSize}
              />
            </Flex>

            <Space
              align='end'
              style={{ flexBasis: '100%', flexDirection: 'column-reverse', width: '100%', marginTop: '28px', }}
            >
              <Form.Item>
                <Button htmlType="button" size="large" style={{marginRight: '8px'}} onClick={onCancel}> 
                  Close
                </Button>
              </Form.Item>
            </Space>
          </Form>
        }
      </Modal>
    </Context.Provider>
  )
}

const HargaJual = () => {
  return (
    <MasterLayout
      pageName="Harga Jual"
      activeMenu="harga-jual" 
      activeParent="master"
      content={Content()}
    />
  )
}

export default HargaJual;