import './Pakaian.scss';
import MasterLayout from '../../components/MasterLayout';
import Spinner from '../../components/Spinner';
import React, { useEffect, useState } from 'react';
import { 
  Flex, 
  Select, 
  Space, 
  Input, 
  Button,
  Table,
  Modal,
  Form,
  notification,
} from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import * as pakaianApi from '../../stores/business/PakaianApi';
import * as masterDataApi from '../../stores/business/MasterDataApi';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const Context = React.createContext({
  name: 'Default',
});

const { confirm } = Modal;

const Content = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSize, setLoadingSize] = useState(false);
  const [data, setData] = useState();
  const [dataSize, setDataSize] = useState();
  const [jenisPakaianOpt, setJenisPakaianOpt] = useState();
  const [filterOptionSelected, setFilterOptionSelected] = useState('jenis');
  const [filterSearchValue, setFilterSearchValue] = useState('');

  const [selectedData, setSelectedData] = useState({
    id: null,
    jenis: "",
    name: "",
    sizeGroup: "",
  });

  const [selectedDataSize, setSelectedDataSize] = useState({
    id: null,
    pakaianId: null,
    ukuran: "",
  });
  
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: "id",
    sortOrder: "descend",
  });
  
  const [tableParamsSize, setTableParamsSize] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
    sortField: "id",
    sortOrder: "ascend",
  });

  const tableColumns = [
    {
      title: 'Jenis Pakaian',
      dataIndex: 'jenis',
      key: 'jenis',
      sorter: true,
    },
    {
      title: 'Nama Pakaian',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Ukuran',
      dataIndex: 'sizeGroup',
      key: 'sizeGroup',
      sorter: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button shape="circle" icon={<EditOutlined />} onClick={() => {onEdit(record)}}/>
          <Button shape="circle" icon={<DeleteOutlined />} onClick={() => {showDeleteConfirm(record);}}/>
        </Space>
      ),
    },
  ];

  const tableColumnsSize = [
    {
      title: 'Ukuran',
      dataIndex: 'ukuran',
      key: 'ukuran',
      width: '50%',
      sorter: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button shape="circle" icon={<EditOutlined />} onClick={() => {onEditSize(record)}}/>
          <Button shape="circle" icon={<DeleteOutlined />} onClick={() => {showDeleteConfirmSize(record);}}/>
        </Space>
      ),
    },
  ];

  const filterOptions = [
    {value: 'jenis', label: 'Jenis Pakaian'},
    {value: 'name', label: 'Nama Pakaian'},
    {value: 'sizeGroup', label: 'Ukuran'},
  ];

  const openNotification = (message, description, type) => {
    if (type === "error") {
      api.error({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    } else if (type === "success") {
      api.success({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    }
  };

  const fetchJenisPakaian = async () => {
    const response = await dispatch(masterDataApi.getJenisPakaian());
    if (response.status === 200) {
      setJenisPakaianOpt(response.data);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/pakaian")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  }

  const fetchData = async () => {
    setLoading(true);

    var sortField = tableParams.sortField === undefined ? "id" : tableParams.sortField;
    var sortOrder = tableParams.sortOrder === "ascend" ? "asc" : "desc"

    const response = await dispatch(pakaianApi.getPakaianPage({
      "page": tableParams.pagination.current,
      "size": tableParams.pagination.pageSize,
      "sort": [
        sortField + " " + sortOrder,
      ],
      "search": [
        {
          field: filterOptionSelected,
          value: filterSearchValue
        }
      ]
    }));
    if (response.status === 200) {
      setData(response.data.contents);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response.data.totalData,
        }
      });
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/pakaian")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoading(false);
  };

  const fetchDataSize = async () => {
    setLoadingSize(true);

    var sortField = tableParamsSize.sortField === undefined ? "id" : tableParamsSize.sortField;
    var sortOrder = "";
    if (tableParamsSize.sortOrder === undefined) {
      sortField = "id";
      sortOrder = "asc";
    } else {
      sortOrder = tableParamsSize.sortOrder === "ascend" ? "asc" : "desc";
    }

    const response = await dispatch(pakaianApi.getPakaianSizePage({
      "page": tableParamsSize.pagination.current,
      "size": tableParamsSize.pagination.pageSize,
      "sort": [
        sortField + " " + sortOrder,
      ],
      "search": [
        {
          field: 'pakaianId',
          value: selectedData.id ? selectedData.id + '' : '0',
        }
      ]
    }));
    if (response.status === 200) {
      setDataSize(response.data.contents);
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/pakaian")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoadingSize(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableParams({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });
    
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleTableSizeChange = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableParamsSize({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });
    
    if (pagination.pageSize !== tableParamsSize.pagination?.pageSize) {
      setDataSize([]);
    }
  };

  const handleChangeOption = (value) => {
    setFilterOptionSelected(value);
  };

  const handleChangeValue = (e) => {
    setFilterSearchValue(e.target.value);
  };

  const handleChangeSizeValue = (e) => {
    setSelectedDataSize({
      ...selectedDataSize,
      ukuran: e.target.value,
    });
  };

  const add = async () => {
    const response = await dispatch(pakaianApi.initPakaian());
    if (response.status === 200) {
      onEdit(response.data);
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  }

  const onEdit = (record) => {
    setSelectedData(record);
    form.setFieldsValue(record);
    setSelectedDataSize({
      ...selectedDataSize,
      pakaianId: record.id,
    })
    setIsOpenModal(true);
  }

  const onEditSize = (record) => {
    setSelectedDataSize(record);
  }

  const onFinish = async (values) => {
    setIsOpenModal(false);
    values.id = selectedData.id;
    const response = await dispatch(pakaianApi.createOrUpdatePakaian(values));
    if (response.status === 200) {
      let message = selectedData.id !== null ? 'Success to edit pakaian!' : 'Success to add new pakaian!';
      openNotification('Success', message, 'success');
      form.setFieldsValue({
        id: null,
        jenis: "",
        name: "",
        sizeGroup: "",
      });
      setSelectedData({
        id: null,
        jenis: "",
        name: "",
        sizeGroup: "",
      });
      setSelectedDataSize({
        id: null,
        pakaianId: null,
        ukuran: "",
      });
      if (tableParams.pagination.current > 1) {
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            current: 1,
          }
        });
      } else {
        fetchData();
      }
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
      setIsOpenModal(true);
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const onCancel = () => {
    form.resetFields();
    form.setFieldsValue({
      id: null,
      jenis: "",
      name: "",
    });
    setSelectedData({
      id: null,
      jenis: "",
      name: "",
    });
    setSelectedDataSize({
      id: null,
      pakaianId: null,
      ukuran: "",
    });
    setIsOpenModal(false);

    setTableParamsSize({
      ...tableParamsSize,
      pagination: {
        ...tableParamsSize.pagination,
        current: 1,
      },
      sortField: "id",
      sortOrder: "ascend",
    });
  };

  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Are you sure delete this pakaian?',
      icon: <ExclamationCircleFilled />,
      content: 'You can\'t undo this operation when finished!',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk() {
        deleteData(record);
      },
    });
  };

  const deleteData = async (record) => {
    setLoading(true);
    const response = await dispatch(pakaianApi.deletePakaian(record.id));
    if (response.status === 200) {
      form.setFieldsValue({
        id: null,
        jenis: "",
        name: "",
      });
      setSelectedData({
        id: null,
        jenis: "",
        name: "",
      });
      if (tableParams.pagination.current > 1) {
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            current: 1,
          }
        });
      } else {
        fetchData();
      }
      openNotification('Success', 'Success to delete pakaian!', 'success');
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoading(false);
  };

  const showDeleteConfirmSize = (record) => {
    confirm({
      title: 'Are you sure delete this size?',
      icon: <ExclamationCircleFilled />,
      content: 'You can\'t undo this operation when finished!',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk() {
        deleteDataSize(record);
      },
    });
  };

  const deleteDataSize = async (record) => {
    setLoadingSize(true);
    const response = await dispatch(pakaianApi.deletePakaianSize(record.id));
    if (response.status === 200) {
      if (tableParamsSize.pagination.current > 1) {
        setTableParamsSize({
          ...tableParamsSize,
          pagination: {
            ...tableParamsSize.pagination,
            current: 1,
          }
        });
      } else {
        fetchDataSize();
      }
      openNotification('Success', 'Success to delete size!', 'success');
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoading(false);
  };

  const addSize = async () => {
    setLoadingSize(true);
    if (selectedDataSize.ukuran?.length > 50) {
      openNotification('Size', "Maximum 50 Characters", 'error');
    }
    const response = await dispatch(pakaianApi.createOrUpdatePakaianSize(selectedDataSize));
    if (response.status === 200) {
      setSelectedDataSize({
        ...selectedDataSize,
        id: null,
        ukuran: "",
      })
      if (tableParamsSize.pagination.current > 1) {
        setTableParamsSize({
          ...tableParamsSize,
          pagination: {
            ...tableParamsSize.pagination,
            current: 1,
          }
        });
      } else {
        fetchDataSize();
      }
      let message = selectedDataSize.id ? "Success to edit size" : "Success to add size!";
      openNotification('Success', message, 'success');
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoadingSize(false);
  }

  useEffect(() => {
    fetchData();
  }, [
    filterOptionSelected,
    filterSearchValue,
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
  ]);
  
  useEffect(() => {
    fetchDataSize();
  }, [
    selectedDataSize.pakaianId,
    tableParamsSize.pagination?.current,
    tableParamsSize.pagination?.pageSize,
    tableParamsSize?.sortOrder,
    tableParamsSize?.sortField,
  ]);
  
  useEffect(() => {
    fetchJenisPakaian();
  }, []);

  return (
    <Context.Provider value={true}>
      {contextHolder}

      <Flex gap="middle" vertical={false}>
        <Space>
          <Select
            size="large"
            value={filterOptionSelected}
            onChange={handleChangeOption}
            style={{ width: 250 }}
            options={filterOptions}
          />
          <Input 
            size="large"
            style={{ width: 300 }}
            onChange={handleChangeValue}
            value={filterSearchValue} />
        </Space>
        <Space align='end'
          style={{ flexBasis: '100%', flexDirection: 'column-reverse' }}
          >
          <Button 
            size="large" 
            type="primary"
            icon={<PlusOutlined />}
            style={{ width: 200 }}
            iconPosition='start'
            onClick={add}
          >
            Add Pakaian
          </Button>
        </Space>
      </Flex>
      <Flex gap="middle" vertical style={{marginTop: '24px'}}>
        <Table
          columns={tableColumns}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={tableParams.pagination}
          loading={{
            spinning: loading,
            indicator: <Spinner className="spinner"/>
          }}
          onChange={handleTableChange}
        />
      </Flex>
      <Modal
        title={selectedData.jenis === null ? "Add New Pakaian" : "Edit Pakaian"}
        open={isOpenModal}
        width={700}
        closable={false}
        footer={[]}
      >
        <Form
          form={form}
          initialValues={selectedData}
          name="merek-form"
          className="merek-form"
          layout="vertical"
          autoComplete="off"
          autoFocus={true}
          onFinish={onFinish}
        >
          <Space>
            <Form.Item
              layout="vertical"
              label="Nama Pakaian"
              name="name"
              style={{width: '236px'}}
              rules={[
                {
                  required: true,
                  message: 'Please input nama pakaian!',
                },
                {
                  max: 50,
                  message: 'Maximum 50 Characters',
                },
              ]}
            >
              <Input size="large" maxLength={50}/>
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Jenis Pakaian"
              name="jenis"
              style={{width: '200px'}}
              rules={[
                {
                  required: true,
                  message: 'Please select jenis pakaian',
                },
              ]}
            >
              <Select 
                showSearch
                placeholder="Select a jenis pakaian"
                options={jenisPakaianOpt} 
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                optionLabelProp="label"
                size="large" />
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Ukuran"
              name="sizeGroup"
              style={{width: '200px'}}
              rules={[
                {
                  required: true,
                  message: 'Please input ukuran!',
                },
                {
                  max: 50,
                  message: 'Maximum 50 Characters',
                },
              ]}
            >
              <Input size="large" maxLength={50}/>
            </Form.Item>
          </Space>


          <Flex gap="middle" vertical style={{marginBottom: '24px'}}>
            <Table
              columns={tableColumnsSize}
              rowKey={(record) => record.id}
              dataSource={dataSize}
              pagination={tableParamsSize.pagination}
              loading={{
                spinning: loadingSize,
                indicator: <Spinner className="spinner"/>
              }}
              onChange={handleTableSizeChange}
            />
          </Flex>
          <Space>
            <Form.Item
              layout="vertical"
              label="Ukuran (Table)"
              style={{width: '200px'}}
            >
              <Input size="large" 
                maxLength={50}
                onChange={handleChangeSizeValue}
                value={selectedDataSize.ukuran}/>
            </Form.Item>
            <Button 
              type="primary" 
              disabled={selectedDataSize.ukuran === ""}
              size="large"
              style={{marginTop:'6px'}}
              onClick={addSize}
            >
              {selectedDataSize.id === null ? "Add Size" : "Edit Size"}
            </Button>
          </Space>

          <Space
            align='end'
            style={{ flexBasis: '100%', flexDirection: 'column-reverse', width: '100%', marginTop: '28px', }}
          >
            <Form.Item>
              <Button htmlType="button" size="large" style={{marginRight: '8px'}} onClick={onCancel}> 
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" size="large">
                {selectedData.jenis !== null ? "Edit" : "Add"}
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </Modal>
    </Context.Provider>
  )
}

const Pakaian = () => {
  return (
    <MasterLayout
      pageName="Pakaian"
      activeMenu="master-pakaian" 
      activeParent="master"
      content={Content()}
    />
  )
}

export default Pakaian;