import PropTypes from 'prop-types'
import logo1 from '../assets/logo-1.png';
import logo2 from '../assets/logo-2.png';
import logo3 from '../assets/logo-3.png';
import React, { useState, useEffect } from 'react';

const Logo = ({className}) => {
  const [logo, setLogo] = useState(logo1);

  useEffect(() => {
    setTimeout(() => {
      if (logo === logo1) {
        setLogo(logo2);
      } else if (logo === logo2) {
        setLogo(logo3);
      } else {
        setLogo(logo1);
      }
    }, 5500);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (logo === logo1) {
        setLogo(logo2);
      } else if (logo === logo2) {
        setLogo(logo3);
      } else {
        setLogo(logo1);
      }
    }, 5500);
  }, [logo]);

  return (
    <img className={className} src={logo} alt="logo" />
  )
}

Logo.propTypes = {
  className: PropTypes.string,
};

export default Logo;