import React from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types'
import './Uploader.scss';
import {
  CloudUploadOutlined
} from '@ant-design/icons';

const Uploader = ({
  onDrop,
  accept,
  maxFiles
}) => {
  return (
    <Dropzone onDrop={acceptedFiles => onDrop(acceptedFiles)} accept={accept} maxFiles={maxFiles}>
      {({getRootProps, getInputProps}) => (
        <section className="uploader-container">
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <CloudUploadOutlined className="upload-icon"/>
            <p className="pointer">Drag 'n' drop some files here, or click to select files</p>
          </div>
        </section>
      )}
    </Dropzone>
  )
}

Uploader.propTypes = {
  onDrop: PropTypes.func,
  accept: PropTypes.object,
  maxFiles: PropTypes.number,
};

export default Uploader;