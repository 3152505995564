import { CallRestApiSecure } from '../../libs/Api';

export const upload = (files) => async () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }

  const contentype = 'multipart/form-data';

  try {
    const response = await CallRestApiSecure('post', BASE_URL, 'v1/file/upload', formData, {}, {}, contentype);
    return JSON.parse(response);
  } catch (err) {
    return JSON.parse(err.response.data);
  }
};