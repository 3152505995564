import PropTypes from 'prop-types'
import spinner from '../assets/spinner.svg';

const Spinner = ({className}) => {
  return (
    <img className={className} src={spinner} alt="logo" />
  )
}

Spinner.propTypes = {
  className: PropTypes.string,
};

export default Spinner;