import './Merek.scss';
import MasterLayout from '../../components/MasterLayout';
import Spinner from '../../components/Spinner';
import Uploader from '../../components/Uploader';
import React, { useEffect, useState } from 'react';
import { 
  Flex, 
  Select, 
  Space, 
  Input, 
  Button,
  Table,
  Modal,
  Form,
  notification,
  Spin,
  Image,
  Upload,
} from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import * as merekApi from '../../stores/business/MerekApi';
import * as fileApi from '../../stores/business/FileApi';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import imageCompression from 'browser-image-compression';

const Context = React.createContext({
  name: 'Default',
});

const { confirm } = Modal;

const Content = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const nav = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [data, setData] = useState();
  const [filterOptionSelected, setFilterOptionSelected] = useState('name');
  const [filterSearchValue, setFilterSearchValue] = useState('');
  const [selectedData, setSelectedData] = useState({
    id: null,
    name: "",
    description: "",
    logo: "",
  });
  
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: "id",
    sortOrder: "descend",
  });

  const tableColumns = [
    {
      title: 'Nama Merek',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button shape="circle" icon={<EditOutlined />} onClick={() => {onEdit(record)}}/>
          <Button shape="circle" icon={<DeleteOutlined />} onClick={() => {showDeleteConfirm(record);}}/>
        </Space>
      ),
    },
  ];

  const filterOptions = [
    {value: 'name', label: 'Nama Merek'},
  ];

  const fetchData = async () => {
    setLoading(true);

    var sortField = tableParams.sortField === undefined ? "id" : tableParams.sortField;
    var sortOrder = tableParams.sortOrder === "ascend" ? "asc" : "desc"

    const response = await dispatch(merekApi.getPage({
      "page": tableParams.pagination.current,
      "size": tableParams.pagination.pageSize,
      "sort": [
        sortField + " " + sortOrder,
      ],
      "search": [
        {
          field: filterOptionSelected,
          value: filterSearchValue
        }
      ]
    }));
    if (response.status === 200) {
      setData(response.data.contents);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response.data.totalData,
        }
      });
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/merek")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
    setLoading(false);
  };

  const openNotification = (message, description, type) => {
    if (type === "error") {
      api.error({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    } else if (type === "success") {
      api.success({
        duration: 3,
        message: message,
        description: description,
        placement: 'top',
      });
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    var sortField = sorter.field;
    var sortOrder = sorter.order;
    setTableParams({
      pagination,
      filters,
      sortOrder: sortOrder,
      sortField: sortField,
    });
    
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleChangeOption = (value) => {
    setFilterOptionSelected(value);
  };

  const handleChangeValue = (e) => {
    setFilterSearchValue(e.target.value);
  };

  const add = () => {
    form.setFieldsValue({
      id: null,
      name: "",
      description: "",
      logo: "",
    });
    setSelectedData({
      id: null,
      name: "",
      description: "",
      logo: "",
    });
    setIsOpenModal(true);
  }

  const onFinish = async (values) => {
    setIsOpenModal(false);
    values.logo = selectedData.logo;
    const response = await (selectedData.id === null ? 
      dispatch(merekApi.create(values)) : dispatch(merekApi.update(selectedData.id, values)));
    if (response.status === 200) {
      let message = selectedData.id !== null ? 'Success to edit merek!' : 'Success to add new merek!';
      openNotification('Success', message, 'success');
      form.setFieldsValue({
        id: null,
        name: "",
        description: "",
        logo: "",
      });
      setSelectedData({
        id: null,
        name: "",
        description: "",
        logo: "",
      });
      if (tableParams.pagination.current > 1) {
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            current: 1,
          }
        });
      } else {
        fetchData();
      }
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/merek")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
      setIsOpenModal(true);
    } else {
      openNotification('Error', 'Something went wrong. Please try again', 'error');
    }
  };

  const onCancel = () => {
    form.resetFields();
    form.setFieldsValue({
      id: null,
      name: "",
      description: "",
      logo: "",
    });
    setSelectedData({
      id: null,
      name: "",
      description: "",
      logo: "",
    });
    setIsOpenModal(false);
  };

  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Are you sure delete this merek?',
      icon: <ExclamationCircleFilled />,
      content: 'You can\'t undo this operation when finished!',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk() {
        deleteData(record);
      },
    });
  };

  const deleteData = async (record) => {
    setLoading(true);
    const response = await dispatch(merekApi.deleteMerek(record.id));
    if (response.status === 200) {
      form.setFieldsValue({
        id: null,
        name: "",
        description: "",
        logo: "",
      });
      setSelectedData({
        id: null,
        name: "",
        description: "",
        logo: "",
      });
      if (tableParams.pagination.current > 1) {
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            current: 1,
          }
        });
      } else {
        fetchData();
      }
      openNotification('Success', 'Success to delete merek!', 'success');
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/merek")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setLoading(false);
  };

  const onEdit = (record) => {
    setSelectedData(record);
    form.setFieldsValue(record);
    setIsOpenModal(true)
  }

  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles.length == 0 || acceptedFiles.length > 1) {
      openNotification('Error', 'You only can upload 1 file at the time', 'error');
    }
    setUploadLoading(true);
    const compressedFiles = await Promise.all(acceptedFiles.map(async (file) => {
      const compressedBlob = await imageCompression(file, {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      });
      return new File([compressedBlob], file.name, {type: compressedBlob.type});
    }));
    const response = await dispatch(fileApi.upload(compressedFiles));
    if (response.status === 200) {
      setSelectedData({
        ...selectedData,
        logo: response.data[0].id,
      });
    } else if (response.status === 401) {
      openNotification('Unauthenticated', response.errors[0].message, 'error');
      nav("/login?redirect_to=master/merek")
    } else if (response.status === 403) {
      openNotification('Forbidden', response.errors[0].message, 'error');
    } else if (response.status === 400) {
      openNotification('Error', response.errors[0].message, 'error');
    }
    setUploadLoading(false);
  }

  const onRemove = (file) => {
    setSelectedData({
      ...selectedData,
      logo: "",
    })
  } 

  const onPreview = (file) => {
    setPreviewOpen(true);
  } 

  useEffect(() => {
    if (selectedData.logo !== "" && selectedData.logo !== null) {
      setPreviewImage(BASE_URL + "/v1/file/" + selectedData.logo + "?time=" + Math.floor(new Date().getTime() / 1000));
    } else {
      setPreviewImage('');
    }
  }, [
    selectedData.logo
  ]);

  useEffect(() => {
    fetchData();
  }, [
    filterOptionSelected,
    filterSearchValue,
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
  ]);

  return (
    <Context.Provider value={true}>
      {contextHolder}
      
      <Flex gap="middle" vertical={false}>
        <Space>
          <Select
            size="large"
            value={filterOptionSelected}
            onChange={handleChangeOption}
            style={{ width: 250 }}
            options={filterOptions}
          />
          <Input 
            size="large"
            style={{ width: 300 }}
            onChange={handleChangeValue}
            value={filterSearchValue} />
        </Space>
        <Space align='end'
          style={{ flexBasis: '100%', flexDirection: 'column-reverse' }}
          >
          <Button 
            size="large" 
            type="primary"
            icon={<PlusOutlined />}
            style={{ width: 200 }}
            iconPosition='start'
            onClick={add}
          >
            Add Merek
          </Button>
        </Space>
      </Flex>
      <Flex gap="middle" vertical style={{marginTop: '24px'}}>
        <Table
          columns={tableColumns}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={tableParams.pagination}
          loading={{
            spinning: loading,
            indicator: <Spinner className="spinner"/>
          }}
          onChange={handleTableChange}
        />
      </Flex>
      <Modal
        title={selectedData.id === null ? "Add New Merek" : "Edit Merek"}
        open={isOpenModal}
        width={700}
        closable={false}
        footer={[]}
      >
        <Form
          form={form}
          initialValues={selectedData}
          name="merek-form"
          className="merek-form"
          layout="vertical"
          autoComplete="off"
          autoFocus={true}
          onFinish={onFinish}
        >
          <Form.Item
            layout="vertical"
            label="Nama Merek"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input your merek name!',
              },
              {
                max: 50,
                message: 'Maximum 50 Characters',
              },
            ]}
          >
            <Input size="large" disabled={selectedData.id !== null} maxLength={50}/>
          </Form.Item>
          
          <Form.Item
            layout="vertical"
            label="Deskripsi"
            name="description"
          >
            <Input.TextArea 
              autoSize={{ minRows: 3, maxRows: 5 }}/>
          </Form.Item>

          <Spin spinning={uploadLoading} indicator={<Spinner className="spinner" />} delay={500}>
            {(selectedData.logo === null || selectedData.logo === "") && <Uploader onDrop={onDrop} accept={{'image/*': []}} maxFiles={1} />}
          </Spin>
          {
            selectedData.logo !== null && selectedData.logo !== "" &&
            <Upload
              listType="picture-card"
              onRemove={onRemove}
              onPreview={onPreview}
              fileList={[
                {
                  uid: -1,
                  name: 'click here to preview the image',
                  status: 'done',
                  url: previewImage,
                },
              ]}
            />
          }
          {previewImage !== '' && (
            <Image
              wrapperStyle={{
                display: 'none',
              }}
              preview={{
                visible: previewOpen,
                onVisibleChange: (visible) => setPreviewOpen(visible),
                afterOpenChange: (visible) => !visible,
              }}
              src={previewImage}
            />
          )}

          <Space
            align='end'
            style={{ flexBasis: '100%', flexDirection: 'column-reverse', width: '100%', marginTop: '28px', }}
          >
            <Form.Item>
              <Button htmlType="button" size="large" style={{marginRight: '8px'}} onClick={onCancel}> 
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" size="large">
                {selectedData.id !== null ? "Edit" : "Add"}
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </Modal>
    </Context.Provider>
  )

}

const Merek = () => {
  return (
    <MasterLayout
      pageName="Merek"
      activeMenu="master-merek" 
      activeParent="master"
      content={Content()}
    />
  )
}

export default Merek;