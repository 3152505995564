import { jwtDecode } from 'jwt-decode';

export const GetToken = () => window.localStorage.getItem('reason-season-token');
export const GetUsername = () => window.localStorage.getItem('reason-season-username');
export const GetUserId = () => window.localStorage.getItem('reason-season-user-id');
export const GetFullName = () => window.localStorage.getItem('reason-season-full-name');

const GetTokenExpired = () => window.localStorage.getItem('reason-season-token-expired');

export const SaveToken = (token) => {
  const decoded = jwtDecode(token);
  const expireTime = new Date(decoded.exp * 1000);

  window.localStorage.setItem('reason-season-token', token);
  window.localStorage.setItem('reason-season-username', decoded.username);
  window.localStorage.setItem('reason-season-user-id', decoded.userId);
  window.localStorage.setItem('reason-season-full-name', decoded.fullName);
  window.localStorage.setItem('reason-season-token', token);
  window.localStorage.setItem('reason-season-token-expired', expireTime);
};

export const RemoveToken = () => {
  window.localStorage.removeItem('reason-season-token');
  window.localStorage.removeItem('reason-season-username');
  window.localStorage.removeItem('reason-season-user-id');
  window.localStorage.removeItem('reason-season-full-name');
  window.localStorage.removeItem('reason-season-token');
  window.localStorage.removeItem('reason-season-token-expired');
};

export const IsTokenValid = () => {
  const loginToken = GetToken();
  const date = new Date(GetTokenExpired());

  if (!loginToken || !GetTokenExpired()) {
    return null;
  }

  if (new Date() > date) {
    return null;
  }

  return loginToken;
};

export const DecodeToUser = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken;
  } catch (error) {
    return null;
  }
};
